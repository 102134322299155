import React from "react";
import { AdministrativeArea, AdministrativeAreasProps } from "./types";
import { Container, DetailsContainer, Image, ItemContainer } from "./styles";
import { Text } from "../../../../components/text";
import { Button, Size } from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import { pageUrls } from "../../../urls";
import { TranslationKey } from "@tour-buddy/ui-translations";

const AdministrativeAreaItem = ({ imageUrl, id, name }: AdministrativeArea) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(pageUrls.placeDetails(id));
  };

  return (
    <ItemContainer onClick={handleOnClick}>
      <Image src={imageUrl} alt={name} />
      <DetailsContainer>
        <Text text={name} variant="h3" />
        <Button
          onClick={handleOnClick}
          text={{
            translationKey: TranslationKey["main.plan.placeDetailsButton"],
          }}
          size={Size.small}
        />
      </DetailsContainer>
    </ItemContainer>
  );
};

export const AdministrativeAreas = ({
  regionArea2,
  regionArea3,
  regionArea1,
  country,
  city,
  district,
}: AdministrativeAreasProps) => {
  return (
    <Container>
      {district && <AdministrativeAreaItem {...district} />}
      {city && <AdministrativeAreaItem {...city} />}
      {regionArea3 && <AdministrativeAreaItem {...regionArea3} />}
      {regionArea2 && <AdministrativeAreaItem {...regionArea2} />}
      {regionArea1 && <AdministrativeAreaItem {...regionArea1} />}
      {country && <AdministrativeAreaItem {...country} />}
    </Container>
  );
};
