import { useCreateAccount } from "./useCreateAccount";
import { ButtonContainer, FinishStateContainer, SuccessIcon } from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Button, Variants } from "../../../components/button";
import React from "react";

export const CreateAccountSuccess = ({
  handleOnLoginRedirect,
  handleOnBack,
}: ReturnType<typeof useCreateAccount>["successProps"]) => (
  <FinishStateContainer>
    <SuccessIcon />
    <TextStatic
      translationKey={TranslationKey["main.registerAccount.title"]}
      variant="h1"
    />
    <TextStatic
      translationKey={TranslationKey["main.registerAccount.successfulMessage"]}
      variant="body1"
    />
    <ButtonContainer>
      <Button
        onClick={handleOnLoginRedirect}
        text={{ translationKey: TranslationKey["main.button.login"] }}
      />
      <Button
        onClick={handleOnBack}
        text={{ translationKey: TranslationKey["main.button.back"] }}
        variant={Variants.outlined}
      />
    </ButtonContainer>
  </FinishStateContainer>
);
