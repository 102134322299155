import React from "react";
import { Container } from "./styles";
import { useForgotPassword } from "./useForgotPassword";
import { PageState } from "./types";
import { ForgotPasswordSuccess } from "./contentSuccess";
import { ForgotPasswordForm } from "./contentForm";
import { ForgotPasswordError } from "./contentError";

export const ForgotPassword = () => {
  const { errorProps, successProps, formProps, pageState } =
    useForgotPassword();

  return (
    <Container>
      {pageState === PageState.success && (
        <ForgotPasswordSuccess {...successProps} />
      )}
      {pageState === PageState.error && <ForgotPasswordError {...errorProps} />}
      {pageState === PageState.form && <ForgotPasswordForm {...formProps} />}
    </Container>
  );
};
