import { useForgotPassword } from "./useForgotPassword";
import {
  ButtonContainer,
  FormContainer,
  InputContainer,
  RegisterContainer,
} from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Button, Size, Variants } from "../../../components/button";
import { Input } from "../../../components/input";
import React from "react";

export const ForgotPasswordForm = ({
  isValid,
  handleOnLoginRedirect,
  handleOnSubmit,
  emailInput,
}: ReturnType<typeof useForgotPassword>["formProps"]) => (
  <FormContainer>
    <TextStatic
      translationKey={TranslationKey["main.forgotPassword.title"]}
      variant="h1"
    />
    <RegisterContainer>
      <TextStatic
        translationKey={TranslationKey["main.forgotPassword.loginRedirectText"]}
        variant="body2"
      />
      <Button
        onClick={handleOnLoginRedirect}
        text={{
          translationKey:
            TranslationKey["main.forgotPassword.loginRedirectButton"],
        }}
        variant={Variants.text}
        size={Size.tiny}
      />
    </RegisterContainer>
    <InputContainer>
      <Input
        placeholder={TranslationKey["main.input.label.email"]}
        {...emailInput.inputProps}
        isFullWidth
      />
    </InputContainer>
    <ButtonContainer>
      <Button
        onClick={handleOnSubmit}
        text={{ translationKey: TranslationKey["main.forgotPassword.submit"] }}
        isFullWidth
        isDisabled={!isValid}
      />
    </ButtonContainer>
  </FormContainer>
);
