import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../../components/text";
import { pageUrls } from "../../../urls";
import { Button } from "../../../../components/button";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { PlaceListItemProps } from "./types";
import { RightContainer, Image, ItemContainer } from "./styles";

export const PlaceListItem = ({ imageUrl, id, name }: PlaceListItemProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(pageUrls.placeDetails(id));
  };

  return (
    <ItemContainer onClick={handleOnClick}>
      <Image src={imageUrl} alt={id} />
      <RightContainer>
        <Text text={name} variant="h2" />
        <Button
          onClick={handleOnClick}
          text={{
            translationKey: TranslationKey["main.plan.placeDetailsButton"],
          }}
        />
      </RightContainer>
    </ItemContainer>
  );
};
