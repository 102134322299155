import styled from "@emotion/styled";
import { theme } from "../../../../theme";
import { Colors } from "../../../../types";

export const Container = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

export const DetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
`;

export const ItemContainer = styled("div")<{ isGray?: boolean }>`
  display: flex;
  flex-direction: row;
  border-radius: ${theme.borderRadius.card}px;
  border: 1px solid
    ${({ isGray }) =>
      isGray ? theme.colors.gray.light : theme.colors[Colors.primary].main};
  cursor: pointer;

  & > h3 {
    white-space: nowrap;
  }
`;

export const Image = styled("img")`
  width: 80px;
  height: 80px;
  border-top-left-radius: ${theme.borderRadius.card}px;
  border-bottom-left-radius: ${theme.borderRadius.card}px;
`;
