import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../../../components/text";
import { pageUrls } from "../../../../urls";
import { Button } from "../../../../../components/button";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { PlaceProps } from "../types";
import { RightContainer, Image, ItemContainer } from "./styles";
import {
  TiqetsDiscoveryWidget,
  TiqetsDiscoveryWidgetProps,
} from "../../../../../components/promotions";
import { Constants } from "@tour-buddy/interfaces";

export const Place = ({ place }: PlaceProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(pageUrls.placeDetails(id));
  };

  const name = place?.name || "";
  const id = place?.id || "";
  const imageUrl = place?.images?.[0]?.thumbnailLink || "";
  const tiqetsIntegration = place?.integration?.find(
    ({ type }) => type === Constants.PLACE_INTEGRATION_TYPES.tiqets
  );
  const tiqetsProps: TiqetsDiscoveryWidgetProps | null = tiqetsIntegration
    ? {
        destinationId: tiqetsIntegration.value,
        destinationType:
          tiqetsIntegration.meta?.tiqets?.destinationType || "venue",
        campaignId: "article",
      }
    : null;

  return (
    <>
      {tiqetsProps && <TiqetsDiscoveryWidget {...tiqetsProps} />}
      <ItemContainer onClick={handleOnClick}>
        <Image src={imageUrl} alt={id} />
        <RightContainer>
          <Text text={name} variant="h2" />
          <Button
            onClick={handleOnClick}
            text={{
              translationKey: TranslationKey["main.plan.placeDetailsButton"],
            }}
          />
        </RightContainer>
      </ItemContainer>
    </>
  );
};
