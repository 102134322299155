import React from "react";
import { TextProps, Variants } from "./types";
import { TextH1, TextH2, TextBody1, TextH3, TextBody2 } from "./styles";

const variantMap: Record<Variants, React.FC<any>> = {
  h1: TextH1,
  h2: TextH2,
  h3: TextH3,
  body1: TextBody1,
  body2: TextBody2,
};

export const Text: React.FC<TextProps> = ({
  variant = "body1",
  text,
  ...rest
}) => {
  const Text = variantMap[variant] || TextBody1;

  return <Text {...rest}>{text}</Text>;
};
