import React from "react";
import { TextStaticProps } from "./types";
import { Text } from "./text";
import { useTranslation } from "@tour-buddy/ui-translations";

export const TextStatic: React.FC<TextStaticProps> = ({
  translationInputMap,
  translationKey,
  variant,
}) => {
  const text = useTranslation(translationKey, translationInputMap) || "";

  const textProps = {
    variant,
    text,
  };

  return <Text {...textProps} />;
};
