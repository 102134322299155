import { ButtonContainer, StyledCheckCircleIcon } from "./styles";
import React from "react";
import { Button, Variants } from "../../../components/button";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { useVerifyAccount } from "./useVerifyAccount";
import { TextStatic } from "../../../components/text";

export const ContentSuccess = ({
  handleLoginClick,
  handleGoBackClick,
}: ReturnType<typeof useVerifyAccount>["successProps"]) => (
  <>
    <StyledCheckCircleIcon />
    <TextStatic
      translationKey={TranslationKey["main.verifyAccount.title"]}
      variant="h1"
    />
    <TextStatic
      translationKey={TranslationKey["main.verifyAccount.success"]}
      variant="body1"
    />
    <ButtonContainer>
      <Button
        variant={Variants.contained}
        onClick={handleLoginClick}
        text={{ translationKey: TranslationKey["main.button.login"] }}
      />
      <Button
        variant={Variants.outlined}
        onClick={handleGoBackClick}
        text={{ translationKey: TranslationKey["main.button.back"] }}
      />
    </ButtonContainer>
  </>
);
