import { Theme } from "./types";

export const theme: Theme = {
  colors: {
    primary: {
      light: "#FFD8D6",
      main: "#FF5C57",
      dark: "#8F0500",
      contrast: "#FFFFFF",
    },
    secondary: {
      light: "#C5E7E6",
      main: "#36827F",
      dark: "#1E4847",
      contrast: "#FFFFFF",
    },
    info: {
      light: "#99C2EB",
      main: "#246eb9",
      dark: "#1B5288",
      contrast: "#FFFFFF",
    },
    error: {
      light: "#EB9E99",
      main: "#C42F28",
      dark: "#98251F",
      contrast: "#FFFFFF",
    },
    success: {
      light: "#a4eb99",
      main: "#45b626",
      dark: "#24831a",
      contrast: "#FFFFFF",
    },
    text: {
      contrast: "#dedede",
      light: "#6c757d",
      main: "#1e1e1e",
      dark: "#000000",
    },
    gray: {
      light: "#dee2e6",
      main: "#6c757d",
      dark: "#343a40",
      contrast: "#1e1e1e",
    },
  },
  font: {
    fontFamily: "K2D",
    fontWeights: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
    },
    sizes: {
      h1: 32,
      h2: 24,
      h3: 20,
      body1: 18,
      body2: 16,
    },
  },
  borderRadius: {
    input: 20,
    button: 50,
    card: 20,
  },
  mediaQueries: {
    desktop: "@media (min-width: 768px)",
    mobile: "@media (max-width: 768px)",
  },
  skeleton: {
    highlightColor: "#f0f4f6",
    baseColor: "#d8dce0",
  },
};
