import styled from "@emotion/styled";
import { theme } from "../../../../theme";

export const Title = styled("div")`
  font-weight: ${theme.font.fontWeights.bold};
  font-size: ${theme.font.sizes.h3}px;
  margin-bottom: 16px;
  align-self: flex-start;
`;

export const Container = styled("div")`
  position: sticky;
  top: 72px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${theme.colors.gray.light};
  border-bottom: 1px solid ${theme.colors.gray.light};
  background-color: white;
  width: 250px;
  max-height: 400px;
  overflow-y: auto;

  & > div:hover {
    font-weight: ${theme.font.fontWeights.bold};
  }

  ${theme.mediaQueries.mobile} {
    display: none;
  }
`;
