import React, { useEffect, useRef, useState } from "react";
import { PlacesListHorizontalProps } from "./types";
import {
  ArrowLeftContainer,
  ArrowLeftIconStyled,
  ArrowRightContainer,
  ArrowRightIconStyled,
  Container,
  HeaderContainer,
  ListContainer,
  ListWrapper,
} from "./styles";
import { TextStatic } from "../../../../components/text";
import { PlaceListItem } from "./placeListItem";

const SCROLL_OFFSET = 300;

export const PlacesListHorizontal = ({
  items,
  header,
}: PlacesListHorizontalProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [shouldRenderScroll, setShouldRenderScroll] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(SCROLL_OFFSET);

  useEffect(() => {
    const fullListWidth = scrollContainerRef?.current?.scrollWidth || 0;
    const visibleListWidth = scrollContainerRef?.current?.clientWidth || 0;

    const newShouldRenderScroll = fullListWidth > visibleListWidth;
    if (newShouldRenderScroll) {
      setShouldRenderScroll(newShouldRenderScroll);
      setScrollOffset(parseInt(`${visibleListWidth * 0.8}`, 10));
      setShowRightArrow(true);
    }
  }, [scrollContainerRef]);

  const handleOnLeftClick = () => {
    if (!showLeftArrow) return null;

    if (scrollContainerRef?.current) {
      const newScrollValue =
        scrollContainerRef.current.scrollLeft - scrollOffset;
      scrollContainerRef?.current.scrollTo({
        left: newScrollValue,
        behavior: "smooth",
      });

      if (!shouldRenderScroll) return null;

      if (newScrollValue <= 0) {
        setShowLeftArrow(false);
      } else {
        setShowLeftArrow(true);
      }

      setShowRightArrow(true);
    }
  };

  const handleOnRightClick = () => {
    if (!showRightArrow) return null;

    if (scrollContainerRef?.current) {
      const newScrollValue =
        scrollContainerRef.current.scrollLeft + scrollOffset;
      scrollContainerRef?.current.scrollTo({
        left: newScrollValue,
        behavior: "smooth",
      });

      if (!shouldRenderScroll) return null;

      setShowLeftArrow(true);

      const fullListWidth = scrollContainerRef?.current?.scrollWidth || 0;
      const visibleListWidth = scrollContainerRef?.current?.clientWidth || 0;
      if (newScrollValue >= fullListWidth - visibleListWidth) {
        setShowRightArrow(false);
      } else {
        setShowRightArrow(true);
      }
    }
  };

  return (
    <Container>
      {header?.name?.translationKey && (
        <HeaderContainer>
          <TextStatic
            translationKey={header?.name?.translationKey}
            translationInputMap={header?.name?.translationInputMap}
            variant="h2"
          />
        </HeaderContainer>
      )}
      <ListWrapper>
        {showLeftArrow && (
          <ArrowLeftContainer onClick={handleOnLeftClick}>
            <ArrowLeftIconStyled />
          </ArrowLeftContainer>
        )}
        <ListContainer ref={scrollContainerRef}>
          {items.map((item) => (
            <PlaceListItem {...item} />
          ))}
        </ListContainer>
        {showRightArrow && (
          <ArrowRightContainer onClick={handleOnRightClick}>
            <ArrowRightIconStyled />
          </ArrowRightContainer>
        )}
      </ListWrapper>
    </Container>
  );
};
