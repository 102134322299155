import React, { useEffect, useRef, useState } from "react";
import { ImageGalleryProps } from "./types";
import { Text } from "../../../../components/text";
import {
  Container,
  ScrollContainer,
  ArrowLeftIconStyled,
  ArrowRightIconStyled,
  ArrowButtonLeft,
  ArrowButtonRight,
  ImageNumbersContainer,
} from "./styles";
import { ImageItem } from "./imageItem";

const SCROLL_OFFSET = 300;

export const ImageGallery = ({ images }: ImageGalleryProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [currentImage, setCurrentImage] = useState<number>(1);
  const [scrollOffset, setScrollOffset] = useState(SCROLL_OFFSET);

  useEffect(() => {
    const visibleListWidth = scrollContainerRef?.current?.clientWidth || 0;
    setScrollOffset(visibleListWidth);
  }, [scrollContainerRef]);

  useEffect(() => {
    const newScrollValue = scrollOffset * (currentImage - 1);
    scrollContainerRef?.current?.scrollTo({
      left: newScrollValue,
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage]);

  const handleNextImage = () => {
    const newImagePosition =
      currentImage === images.length ? 1 : currentImage + 1;
    setCurrentImage(newImagePosition);
  };

  const handlePreviousImage = () => {
    const newImagePosition =
      currentImage === 1 ? images.length : currentImage - 1;
    setCurrentImage(newImagePosition);
  };

  return (
    <Container>
      <ScrollContainer ref={scrollContainerRef}>
        {images.map((image) => (
          <ImageItem {...image} />
        ))}
      </ScrollContainer>
      <ImageNumbersContainer>
        <Text text={`${currentImage}/${images.length}`} variant="h3" />
      </ImageNumbersContainer>
      <ArrowButtonLeft onClick={handlePreviousImage}>
        <ArrowLeftIconStyled />
      </ArrowButtonLeft>
      <ArrowButtonRight onClick={handleNextImage}>
        <ArrowRightIconStyled />
      </ArrowButtonRight>
    </Container>
  );
};
