import {
  isRequiredInvalid,
  isPatternInvalid,
  isMinLengthInvalid,
  isMaxLengthInvalid,
} from "./string";

const VALIDATION_STRING_FUNCTION_MAP = {
  required: isRequiredInvalid,
  minLength: isMinLengthInvalid,
  maxLength: isMaxLengthInvalid,
  pattern: isPatternInvalid,
};

export const VALIDATION_FUNCTION_MAP = {
  string: VALIDATION_STRING_FUNCTION_MAP,
};
