import React from "react";
import { ParagraphProps } from "./types";
import { ParagraphContainer, ParagraphText } from "./styles";
import Skeleton from "react-loading-skeleton";

export const Paragraph = ({ text }: ParagraphProps) => {
  return (
    <ParagraphContainer>
      <ParagraphText>{text}</ParagraphText>
    </ParagraphContainer>
  );
};

export const ParagraphSkeleton = () => {
  return (
    <ParagraphContainer>
      <ParagraphText>
        <Skeleton count={8} width="100%" />
        <Skeleton width={300} />
      </ParagraphText>
    </ParagraphContainer>
  );
};
