import React from "react";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";

export const ContentValidation = () => (
  <>
    <TextStatic
      translationKey={TranslationKey["main.verifyAccount.title"]}
      variant="h1"
    />
    <TextStatic
      translationKey={TranslationKey["main.verifyAccount.waiting"]}
      variant="body1"
    />
  </>
);
