import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Layout } from "../components/layout";
import { pageUrls } from "./urls";

import { BlogHomepage } from "./blog/homepage";
import { Article } from "./blog/article";
import { ArticleList } from "./blog/articleList";

import { LandingPage } from "./app/landingPage";
import { PlaceDetails } from "./app/placeDetails";
import { PlanDetails } from "./app/planDetails";
import { PlansList } from "./app/plansList";

import { PrivacyPolicy } from "./user/privacyPolicy";
import { RemoveAccount } from "./user/removeAccount";
import { TermsAndConditions } from "./user/termsAndConditions";
import { VerifyAccount } from "./user/verifyAccount";
import { Login } from "./user/login";
import { CreateAccount } from "./user/createAccount";
import { ForgotPassword } from "./user/forgotPassword";
import { ResetPassword } from "./user/resetPassword";

const defaultLayoutConfig = {
  showSearch: false,
  showDrawer: false,
};

interface SetLayoutConfigProps {
  showSearch?: boolean;
  showDrawer?: boolean;
}
export const Pages = () => {
  // const [showSearch, setShowSearch] = useState(defaultLayoutConfig.showSearch);
  // const [showDrawer, setShowDrawer] = useState(defaultLayoutConfig.showDrawer);
  const [, setShowSearch] = useState(defaultLayoutConfig.showSearch);
  const [, setShowDrawer] = useState(defaultLayoutConfig.showDrawer);

  const setLayoutConfig = (props?: SetLayoutConfigProps) => {
    setShowSearch(props?.showSearch ?? defaultLayoutConfig.showSearch);
    setShowDrawer(props?.showDrawer ?? defaultLayoutConfig.showDrawer);
    return null;
  };

  return (
    <Layout>
      <Routes>
        <Route
          path={pageUrls.landingPage()}
          element={<LandingPage />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.homepage()}
          element={<BlogHomepage />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.article()}
          element={<Article />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.articles()}
          element={<ArticleList />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.placeDetails()}
          element={<PlaceDetails />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.userPlanDetails()}
          element={<PlanDetails />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.userPlans()}
          element={<PlansList />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.privacyPolicy()}
          element={<PrivacyPolicy />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.termsAndConditions()}
          element={<TermsAndConditions />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.removeAccount()}
          element={<RemoveAccount />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.verifyAccount()}
          element={<VerifyAccount />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.login()}
          element={<Login />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.createAccount()}
          element={<CreateAccount />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.forgotPassword()}
          element={<ForgotPassword />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.resetPassword()}
          element={<ResetPassword />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.verifyAccount()}
          element={<VerifyAccount />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path="*"
          element={<Navigate to={pageUrls.homepage()} replace={true} />}
        />
      </Routes>
    </Layout>
  );
};
