import React from "react";
import {
  ButtonContainer,
  Container,
  FormContainer,
  InputContainer,
  RegisterContainer,
} from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Input } from "../../../components/input";
import { Button, Size, Variants } from "../../../components/button";
import { useLogin } from "./useLogin";

export const Login = () => {
  const {
    handleOnSubmit,
    handleOnRegisterRedirect,
    handleOnForgotPassword,
    loginInput,
    passwordInput,
    isValid,
  } = useLogin();

  return (
    <Container>
      <FormContainer>
        <TextStatic
          translationKey={TranslationKey["main.login.title"]}
          variant="h1"
        />
        <RegisterContainer>
          <TextStatic
            translationKey={TranslationKey["main.login.registerRedirectText"]}
            variant="body2"
          />
          <Button
            onClick={handleOnRegisterRedirect}
            text={{
              translationKey:
                TranslationKey["main.login.registerRedirectButton"],
            }}
            variant={Variants.text}
            size={Size.tiny}
          />
        </RegisterContainer>
        <InputContainer>
          <Input
            placeholder={TranslationKey["main.input.label.email"]}
            {...loginInput.inputProps}
            isFullWidth
          />
          <Input
            placeholder={TranslationKey["main.input.label.password"]}
            {...passwordInput.inputProps}
            isFullWidth
            type="password"
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            onClick={handleOnSubmit}
            text={{ translationKey: TranslationKey["main.login.submit"] }}
            isFullWidth
            isDisabled={!isValid}
          />
          <Button
            onClick={handleOnForgotPassword}
            text={{
              translationKey:
                TranslationKey["main.login.registerForgotPassword"],
            }}
            variant={Variants.text}
            isFullWidth
          />
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};
