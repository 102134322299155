export const pageUrls = {
  homepage: () => "/",
  article: (language = ":language", articlePath = ":path") =>
    `/article/${language}/${articlePath}`,
  articles: () => `/articles`,
  userPlanDetails: (planId = ":planId") => `/userPlan/${planId}`,
  placeDetails: (placeId = ":placeId") => `/place/${placeId}`,
  userPlans: () => `/userPlans`,
  landingPage: () => "/landing",
  login: () => "/login",
  createAccount: () => "/create_account",
  forgotPassword: () => "/forgot_password",
  privacyPolicy: () => "/privacy_policy",
  removeAccount: () => "/remove_account",
  termsAndConditions: () => "/terms_and_conditions",
  updatePassword: () => "/update_password",
  resetPassword: () => "/reset-password",
  verifyAccount: () => "/verify-account",
};
