import React from "react";
import { useNavigate } from "react-router-dom";
import { Text, TextStatic } from "../../text";
import { pageUrls } from "../../../pages/urls";
import { Button } from "../../button";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { PlanListItemProps } from "./types";
import { getApproxTime } from "../../../utils/time";
import {
  Row,
  RightContainer,
  Image,
  ImagesContainer,
  ItemContainer,
  DetailsContainer,
} from "./styles";

const WIDTH_IMAGE_COUNT_MAP: Record<number, number> = {
  0: 200,
  1: 200,
  2: 100,
  3: 100,
  4: 100,
};
const HEIGHT_IMAGE_COUNT_MAP: Record<number, number> = {
  0: 200,
  1: 200,
  2: 200,
  3: 100,
  4: 100,
};

export const PlanListItem = ({
  imageUrls = [],
  startAddress,
  placeIds,
  id,
  availableDays,
  availableTime,
  name,
}: PlanListItemProps) => {
  const navigate = useNavigate();

  const imagesList = imageUrls.slice(0, 4);
  const imageWidth = WIDTH_IMAGE_COUNT_MAP[imagesList.length] || 200;
  const imageHeight = HEIGHT_IMAGE_COUNT_MAP[imagesList.length] || 200;

  const [firstStartAddress, ...restStartAddress] = startAddress || [];
  const relativeTime =
    availableDays === 1 && availableTime
      ? getApproxTime(availableTime[0])
      : null;
  const { minutes = 0, hours = 0 } = relativeTime || {};

  const handleOnClick = () => {
    navigate(pageUrls.userPlanDetails(id));
  };

  return (
    <ItemContainer onClick={handleOnClick}>
      <ImagesContainer>
        {imageUrls.map((imageUrl, index) => (
          <Image
            key={imageUrl}
            src={imageUrl}
            alt={`${id}_${index}`}
            height={imageHeight}
            width={imageWidth}
            topLeftRadius={index === 0}
            bottomLeftRadius={[0, 2].includes(index)}
          />
        ))}
      </ImagesContainer>
      <RightContainer>
        <DetailsContainer>
          <Text text={name} variant="h3" />
          {restStartAddress?.length ? (
            <TextStatic
              variant="body1"
              translationKey={TranslationKey["main.userPlans.locationMultiple"]}
              translationInputMap={{
                startLocation: firstStartAddress?.displayAddress || "",
                locationNum: restStartAddress?.length || 0,
              }}
            />
          ) : (
            <TextStatic
              variant="body1"
              translationKey={TranslationKey["main.userPlans.locationSingle"]}
              translationInputMap={{
                startLocation: firstStartAddress?.displayAddress || "",
              }}
            />
          )}
          <Row>
            {availableDays > 1 ? (
              <TextStatic
                variant="body1"
                translationKey={TranslationKey["main.userPlans.availableDays"]}
                translationInputMap={{ days: availableDays }}
              />
            ) : (
              <TextStatic
                variant="body1"
                translationKey={TranslationKey["main.userPlans.availableTime"]}
                translationInputMap={{ hours, minutes }}
              />
            )}
            <TextStatic
              variant="body1"
              translationKey={TranslationKey["main.userPlans.placesNumber"]}
              translationInputMap={{ placesNum: placeIds?.length || 0 }}
            />
          </Row>
        </DetailsContainer>
        <Button
          onClick={handleOnClick}
          text={{
            translationKey: TranslationKey["main.userPlans.planDetailsButton"],
          }}
        />
      </RightContainer>
    </ItemContainer>
  );
};
