import React from "react";
import {
  ActionProps,
  DynamicProps,
  LayoutProps,
  UserAuthorizationType,
} from "./types";
import { Header, HeaderProps } from "./header";
import { Drawer, DrawerProps } from "./drawer";
import { Footer } from "./footer";
import { useDrawerContext } from "./drawerProvider";
import { Container, Content, ContentWithFooter } from "./styles";
import { BOTTOM_ACTIONS, HEADER_ACTIONS, TOP_ACTIONS } from "./constants";
import { useNavigate } from "react-router-dom";
import { pageUrls } from "../../pages/urls";
import { useAuth } from "../authProvider";
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageItem,
} from "../../utils/localStorage";
import { useApiRequestContext } from "@tour-buddy/ui-api-provider";

const filterDynamicProps = (
  actions: ActionProps[],
  { isAuthorized }: DynamicProps
) =>
  actions.filter((action) => {
    if (action.userAuthorizationType) {
      if (
        action.userAuthorizationType === UserAuthorizationType.notAuthorized
      ) {
        return !isAuthorized;
      } else if (
        action.userAuthorizationType === UserAuthorizationType.authorized
      ) {
        return isAuthorized;
      } else {
        return true;
      }
    }

    return true;
  });

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { logoutUser } = useApiRequestContext();
  const { isOpen, toggleIsOpen } = useDrawerContext();
  const navigate = useNavigate();
  const { isAuthorized, userData } = useAuth();

  const handleOnLogout = () => {
    logoutUser.request({ variables: { id: userData?.id || "" } });
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA);
    window.location.reload();
    navigate(pageUrls.homepage());
  };

  const actionHandlerProps = {
    navigate,
    onLogout: handleOnLogout,
  };

  const dynamicProps = {
    isAuthorized,
  };

  const topActions = filterDynamicProps(
    TOP_ACTIONS(actionHandlerProps),
    dynamicProps
  );
  const bottomActions = filterDynamicProps(
    BOTTOM_ACTIONS(actionHandlerProps),
    dynamicProps
  );
  const headerActions = filterDynamicProps(
    HEADER_ACTIONS(actionHandlerProps),
    dynamicProps
  );

  const showDrawerIcon = !![...topActions, ...bottomActions].length;

  const headerProps: HeaderProps = {
    desktopOnlyActions: headerActions,
    onLogoClick: () => navigate(pageUrls.homepage()),
    // onLogoClick: () => navigate(pageUrls.landingPage()),
    onNavigationClick: () => {
      toggleIsOpen();
    },
    showDrawerIcon,
  };

  const onClose = () => toggleIsOpen();

  const drawerProps: DrawerProps = {
    bottomActions: bottomActions,
    topActions: topActions,
    mobileOnlyActions: headerActions,
    onClose,
    isOpen,
  };

  return (
    <Container>
      <ContentWithFooter>
        <Content>{children}</Content>
        <Footer />
      </ContentWithFooter>
      <Drawer {...drawerProps} />
      <Header {...headerProps} />
    </Container>
  );
};
