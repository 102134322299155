import React from "react";
import { Container, Title } from "./styles";
import Skeleton from "react-loading-skeleton";

export const TableOfContentSkeleton = () => {
  return (
    <Container>
      <Title>
        <Skeleton width={180} />
      </Title>
      <div>
        <Skeleton width={200} />
        <Skeleton width={240} />
        <Skeleton width={210} />
        <Skeleton width={230} />
        <Skeleton width={100} />
        <Skeleton width={150} />
      </div>
    </Container>
  );
};
