import { HttpError } from "./HttpError";
import { Common } from "@tour-buddy/interfaces";

export class WrongParamsError extends HttpError {
  constructor(
    params?: Partial<Pick<Common.IHttpError, "message" | "error" | "type">>
  ) {
    super({
      statusCode: 400,
      type: "WRONG_PARAMS_ERROR",
      message: "params are not correct",
      error: params,
    });
  }
}
