import styled from "@emotion/styled";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../../components/icons";
import { theme } from "../../../../theme";

export const Container = styled("div")`
  position: relative;
  width: 100%;
  background-color: ${theme.colors.gray.light};
  border-radius: ${theme.borderRadius.card}px;

  & span {
    width: 100%;
    border-radius: ${theme.borderRadius.card}px;
  }
`;

export const ScrollContainer = styled("div")`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
`;

export const ImageNumbersContainer = styled("div")`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ffffff77;
  color: ${theme.colors.gray.main};
  padding: 8px 16px;
  border-top-left-radius: 16px;
`;

export const ItemContainer = styled("div")`
  height: 432px; // 16:9 proportion with 768 width
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled("img")`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

export const ArrowButton = styled("div")`
  padding: 16px 18px;
  background-color: #ffffff77;
  position: absolute;
  top: 168px;
  border-radius: ${theme.borderRadius.button}px;
  cursor: pointer;
`;

export const ArrowButtonLeft = styled(ArrowButton)`
  left: 16px;
`;

export const ArrowButtonRight = styled(ArrowButton)`
  right: 16px;
`;

export const ArrowLeftIconStyled = styled(ArrowLeftIcon)`
  width: 32px;
  height: 32px;
  stroke: ${theme.colors.gray.main};
`;

export const ArrowRightIconStyled = styled(ArrowRightIcon)`
  width: 32px;
  height: 32px;
  stroke: ${theme.colors.gray.main};
`;
