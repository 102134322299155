import styled from "@emotion/styled";
import { Colors, ButtonProps } from "./types";
import { theme } from "../../theme";

const Container = styled("button")<{
  color: Colors;
  paddingHorizontal: string;
  paddingVertical: string;
  fullWidth: boolean;
  align?: ButtonProps["align"];
}>`
  cursor: pointer;
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical} ${paddingHorizontal}`};
  display: inline-flex;
  flex-flow: row;
  border-radius: ${theme.borderRadius.button}px;
  border: none;
  font-weight: ${theme.font.fontWeights.semiBold};
  transition: background-color 100ms ease-in, border 100ms ease-in,
    color 100ms ease-in;
  width: ${({ fullWidth }) => (fullWidth ? `100%` : "auto")};
  justify-content: ${({ align }) => align};

  & > * {
    text-align: ${({ align }) => align};
  }
`;

export const ContainerOutlined = styled(Container)`
  border: 2px solid ${({ color }) => theme.colors[color].main};
  background-color: white;
  color: ${({ color }) => theme.colors[color].main};

  & > svg {
    transition: stroke 100ms ease-in;
  }

  &:hover {
    color: ${({ color }) => theme.colors[color].dark};
    border: 2px solid ${({ color }) => theme.colors[color].dark};

    & > svg {
      stroke: ${({ color }) => theme.colors[color].dark};
    }
  }

  &:disabled,
  &:disabled:hover {
    border: 2px solid ${theme.colors.gray.main};
    stroke: ${theme.colors.gray.main};
    color: ${theme.colors.gray.main};
  }
`;

export const ContainerContained = styled(Container)`
  background-color: ${({ color }) => theme.colors[color].main};
  color: ${({ color }) => theme.colors[color].contrast};

  &:hover {
    background-color: ${({ color }) => theme.colors[color].dark};
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${theme.colors.gray.light};
    color: ${theme.colors.gray.main};
  }
`;

export const ContainerText = styled(Container)`
  background-color: white;
  color: ${({ color }) => theme.colors[color].main};

  & > svg {
    transition: stroke 100ms ease-in;
  }

  &:hover {
    color: ${({ color }) => theme.colors[color].dark};
    & > svg {
      stroke: ${({ color }) => theme.colors[color].dark};
    }
  }

  &:disabled,
  &:disabled:hover {
    color: ${theme.colors.gray.main};
  }
`;
