import { ValidationSchema } from "@tour-buddy/validation";

export const INPUT_PASSWORD_VALIDATION: ValidationSchema[] = [
  {
    type: "string",
    required: true,
    message: "Missing password",
    errorCode: "missingPassword",
  },
  {
    type: "string",
    pattern: "[0-9]+",
    message: "At least one number",
    errorCode: "passwordRequirements",
  },
  {
    type: "string",
    pattern: "[A-Z]+",
    message: "At least one capital letter",
    errorCode: "passwordRequirements",
  },
  {
    type: "string",
    pattern: "[a-z]+",
    message: "At least one lowercase letter",
    errorCode: "passwordRequirements",
  },
  {
    type: "string",
    minLength: 8,
    message: "At least 8 characters",
    errorCode: "passwordRequirements",
  },
];
