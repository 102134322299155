import React from "react";
import { Container, Content } from "./styles";
import { useArticleList } from "./useArticleList";
import { ArticleList } from "../../../components/articleList";

export const ArticleListPage = () => {
  const { articles, isLoading } = useArticleList();
  return (
    <Container>
      <Content>
        <ArticleList
          articles={articles}
          isLoading={isLoading}
          loadingItemsCount={12}
        />
      </Content>
    </Container>
  );
};
