import React from "react";
import {
  Container,
  Content,
  DetailsContainer,
  PlacesContainer,
} from "./styles";
import Skeleton from "react-loading-skeleton";
import { DayDetailsSkeleton } from "./dayDetails/dayDetails.skeleton";
import { DaySelectorSkeleton } from "./daySelector/daySelector.skeleton";
import { PlaceListItemSkeleton } from "./placeListItem/placeListItem.skeleton";

export const PlanDetailsSkeleton = () => {
  return (
    <Container>
      <Content>
        <DetailsContainer>
          <Skeleton height={48} width={300} />
          <Skeleton height={47} width={150} />
          <DaySelectorSkeleton />
          <DayDetailsSkeleton />
          <PlacesContainer>
            <PlaceListItemSkeleton />
            <PlaceListItemSkeleton />
            <PlaceListItemSkeleton />
            <PlaceListItemSkeleton />
          </PlacesContainer>
        </DetailsContainer>
      </Content>
    </Container>
  );
};
