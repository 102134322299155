import styled from "@emotion/styled";
import { theme } from "../../../../theme";
import { ArrowRightIcon, ArrowLeftIcon } from "../../../../components/icons";
import { Colors } from "../../../../types";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 768px;
  width: 100%;
`;

export const ListWrapper = styled("div")`
  position: relative;
  background-color: ${theme.colors.gray.light};
  border-radius: ${theme.borderRadius.card}px;
  padding: 16px;
`;

export const ListContainer = styled("div")`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  gap: 16px;
`;

export const HeaderContainer = styled("div")``;

export const ItemContainer = styled("div")`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.gray.light};
  border-radius: ${theme.borderRadius.card}px;
  background-color: white;
  cursor: pointer;
  width: 200px;
`;

export const BottomContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  flex: 1;

  & > h2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Image = styled("img")`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: ${theme.borderRadius.card}px;
  border-top-right-radius: ${theme.borderRadius.card}px;
`;

export const ArrowLeftIconStyled = styled(ArrowLeftIcon)`
  width: 24px;
  height: 24px;
  stroke: ${theme.colors[Colors.primary].main};
`;

export const ArrowRightIconStyled = styled(ArrowRightIcon)`
  width: 24px;
  height: 24px;
  stroke: ${theme.colors[Colors.primary].main};
`;

export const ArrowContainer = styled("div")`
  width: 100px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const ArrowLeftContainer = styled(ArrowContainer)`
  left: 0;
  justify-content: flex-start;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const ArrowRightContainer = styled(ArrowContainer)`
  right: 0;
  background: white;
  justify-content: flex-end;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 0) 100%
  );
`;
