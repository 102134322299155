import { useForgotPassword } from "./useForgotPassword";
import { ButtonContainer, FinishStateContainer, SuccessIcon } from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Button, Variants } from "../../../components/button";
import React from "react";

export const ForgotPasswordSuccess = ({
  handleOnBack,
}: ReturnType<typeof useForgotPassword>["successProps"]) => (
  <FinishStateContainer>
    <SuccessIcon />
    <TextStatic
      translationKey={TranslationKey["main.forgotPassword.title"]}
      variant="h1"
    />
    <TextStatic
      translationKey={TranslationKey["main.forgotPassword.successfulMessage"]}
      variant="body1"
    />
    <ButtonContainer>
      <Button
        onClick={handleOnBack}
        text={{ translationKey: TranslationKey["main.button.back"] }}
        variant={Variants.outlined}
      />
    </ButtonContainer>
  </FinishStateContainer>
);
