import { useCreateAccount } from "./useCreateAccount";
import {
  ButtonContainer,
  FormContainer,
  InputContainer,
  RegisterContainer,
} from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Button, Size, Variants } from "../../../components/button";
import { Input } from "../../../components/input";
import React from "react";

export const CreateAccountForm = ({
  isValid,
  handleOnLoginRedirect,
  handleOnSubmit,
  firstNameInput,
  confirmPasswordInput,
  lastNameInput,
  emailInput,
  passwordInput,
}: ReturnType<typeof useCreateAccount>["formProps"]) => (
  <FormContainer>
    <TextStatic
      translationKey={TranslationKey["main.registerAccount.title"]}
      variant="h1"
    />
    <RegisterContainer>
      <TextStatic
        translationKey={
          TranslationKey["main.registerAccount.loginRedirectText"]
        }
        variant="body2"
      />
      <Button
        onClick={handleOnLoginRedirect}
        text={{
          translationKey:
            TranslationKey["main.registerAccount.loginRedirectButton"],
        }}
        variant={Variants.text}
        size={Size.tiny}
      />
    </RegisterContainer>
    <InputContainer>
      <Input
        placeholder={TranslationKey["main.input.label.email"]}
        {...emailInput.inputProps}
        isFullWidth
      />
      <Input
        placeholder={TranslationKey["main.input.label.firstName"]}
        {...firstNameInput.inputProps}
        isFullWidth
      />
      <Input
        placeholder={TranslationKey["main.input.label.lastName"]}
        {...lastNameInput.inputProps}
        isFullWidth
      />
      <Input
        placeholder={TranslationKey["main.input.label.password"]}
        {...passwordInput.inputProps}
        type="password"
        isFullWidth
      />
      <TextStatic
        translationKey={
          TranslationKey["main.registerAccount.passwordRequirements"]
        }
        variant="body2"
      />
      <Input
        placeholder={TranslationKey["main.input.label.confirmPassword"]}
        {...confirmPasswordInput.inputProps}
        type="password"
        isFullWidth
      />
    </InputContainer>
    <ButtonContainer>
      <Button
        onClick={handleOnSubmit}
        text={{ translationKey: TranslationKey["main.registerAccount.submit"] }}
        isFullWidth
        isDisabled={!isValid}
      />
    </ButtonContainer>
  </FormContainer>
);
