import React from "react";
import { TextStaticOptionalProps } from "./types";
import { Text } from "./text";
import { TextStatic } from "./textStatic";

export const TextStaticOptional: React.FC<TextStaticOptionalProps> = ({
  translationInputMap,
  translationKey,
  text,
  variant,
}) => {
  if (translationKey) {
    return (
      <TextStatic
        translationKey={translationKey}
        translationInputMap={translationInputMap}
        variant={variant}
      />
    );
  }

  if (text) {
    return <Text text={text} variant={variant} />;
  }

  return null;
};
