import styled from "@emotion/styled";
import { theme } from "../../theme";
import { Colors } from "../../types";

export const Input = styled("input")<{ isFullWidth?: boolean }>`
  border-radius: ${theme.borderRadius.input}px;
  border: 1px solid ${theme.colors.gray.light};
  padding: 12px 16px;
  font-size: ${theme.font.sizes.body1}px;
  flex: ${({ isFullWidth }) => (isFullWidth ? 1 : "none")};

  &:focus {
    border: 1px solid ${theme.colors[Colors.primary].main};
    outline: 1px solid ${theme.colors[Colors.primary].main};
  }
`;

export const Label = styled("div")<{ isLabelInline?: boolean }>`
  font-size: ${theme.font.sizes.body1}px;
  margin-bottom: ${({ isLabelInline }) => (isLabelInline ? 0 : "8px")};
  margin-right: ${({ isLabelInline }) => (isLabelInline ? "16px" : 0)};
`;

export const Container = styled("div")<{
  isLabelInline?: boolean;
  isFullWidth?: boolean;
}>`
  display: flex;
  flex-direction: ${({ isLabelInline }) => (isLabelInline ? "row" : "column")};
  align-items: ${({ isLabelInline, isFullWidth }) => {
    if (isLabelInline) {
      return "center";
    }

    if (isFullWidth) return "stretch";
    return "flex-start";
  }};
`;
