import React from "react";
import { Container } from "./styles";
import Skeleton from "react-loading-skeleton";

export const DaySelectorSkeleton = () => {
  return (
    <Container>
      <Skeleton height={32} width={32} />
      <Skeleton height={32} width={120} />
      <Skeleton height={32} width={120} />
      <Skeleton height={32} width={120} />
      <Skeleton height={32} width={32} />
    </Container>
  );
};
