import { Variants } from "../button";
import { TranslationKey } from "@tour-buddy/ui-translations";

export interface ActionHandlerProps {
  navigate: (url: string) => void;
  onLogout: () => void;
}

export interface DynamicProps {
  isAuthorized: boolean;
}

export enum UserAuthorizationType {
  authorized = "authorized",
  notAuthorized = "not_authorized",
  all = "all",
}

export interface ActionProps {
  type?: "button" | "component";
  buttonVariant?: Variants;
  translationKey?: TranslationKey;
  userAuthorizationType?: UserAuthorizationType;
  onClick?: () => void;
}

export interface LayoutProps {
  children: React.ReactNode;
}
