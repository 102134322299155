import styled from "@emotion/styled";
import { theme } from "../../../theme";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px - 56px);
`;

export const FormContainer = styled("div")`
  width: 350px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border-radius: ${theme.borderRadius.card}px;
  border: 1px solid ${theme.colors.gray.light};

  ${theme.mediaQueries.desktop} {
    padding: 24px 32px;
  }
`;

export const RegisterContainer = styled("div")`
  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 2px;
  }

  ${theme.mediaQueries.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
