import React from "react";
import type { Image } from "./types";
import { Image as ImageStyled, ItemContainer } from "./styles";

export const ImageItem = ({ alt, url }: Image) => {
  return (
    <ItemContainer>
      <ImageStyled src={url} alt={alt} />
    </ItemContainer>
  );
};
