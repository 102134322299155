import React from "react";
import { HeaderProps } from "./types";
import { Button, Colors, Variants } from "../../button";
import {
  Container,
  DesktopOnlyContent,
  LogoContainer,
  LogoStyled,
  RightContent,
  StyledNavigationMenuIcon,
} from "./styles";

export const Header: React.FC<HeaderProps> = ({
  desktopOnlyActions = [],
  onNavigationClick,
  onLogoClick,
  showDrawerIcon = false,
}) => {
  return (
    <Container>
      <LogoContainer onClick={onLogoClick}>
        <LogoStyled />
      </LogoContainer>
      <RightContent>
        <DesktopOnlyContent>
          {desktopOnlyActions.map(
            ({
              type,
              translationKey,
              onClick,
              buttonVariant = Variants.text,
            }) =>
              type === "button" && (
                <Button
                  text={{ translationKey }}
                  color={Colors.text}
                  variant={buttonVariant}
                  onClick={onClick as VoidFunction}
                  key={translationKey}
                />
              )
          )}
        </DesktopOnlyContent>
        {showDrawerIcon && (
          <Button
            onClick={onNavigationClick}
            Icon={<StyledNavigationMenuIcon />}
            variant={Variants.text}
          />
        )}
      </RightContent>
    </Container>
  );
};
