import React from "react";
import { Container, ScrollContainer } from "./styles";
import Skeleton from "react-loading-skeleton";

export const ImageGallerySkeleton = () => {
  return (
    <Container>
      <ScrollContainer>
        <Skeleton height={432} width="100%" style={{ lineHeight: "normal" }} />
      </ScrollContainer>
    </Container>
  );
};
