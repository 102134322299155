import React, { useEffect, useState } from "react";
import { InputProps } from "./types";
import { Container, Input as InputStyled, Label } from "./styles";
import { TextStatic } from "../text";
import { useTranslation } from "@tour-buddy/ui-translations";
import { validate } from "@tour-buddy/validation";

export const Input: React.FC<InputProps> = ({
  validationSchema,
  type,
  isLabelInline = false,
  isFullWidth = false,
  label,
  placeholder,
  value: defaultValue = "",
  onFocus,
  onBlur,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (validationSchema) {
      const { isError, errorMessage } = validate(
        { value: validationSchema },
        { value },
        false
      );
      setIsValid(!isError);
      setErrorMessage(errorMessage);
      onChange?.({ value, isValid: !isError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, validationSchema]);

  const placeholderText = useTranslation(placeholder) || "";

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange?.({ value: newValue, isValid }, e);
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onBlur?.({ value: newValue, isValid }, e);
  };

  const handleOnFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onFocus?.({ value: newValue, isValid }, e);
  };

  return (
    <Container isLabelInline={isLabelInline} isFullWidth={isFullWidth}>
      {label && (
        <Label isLabelInline={isLabelInline}>
          <TextStatic translationKey={label} />
        </Label>
      )}
      <InputStyled
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        value={value}
        placeholder={placeholderText}
        isFullWidth={isFullWidth}
        type={type}
      />
    </Container>
  );
};
