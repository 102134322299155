import React, { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  TranslationLanguage,
  TranslationsProvider,
} from "@tour-buddy/ui-translations";
import { SessionProvider } from "./components/sessionProvider";
import { Pages } from "./pages";
import { SnackbarProvider } from "notistack";
import { ApiProvider } from "@tour-buddy/ui-api-provider";
import { AuthProvider } from "./components/authProvider";
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from "./utils/localStorage";
import { AppRoot } from "./styles";
import { DrawerProvider } from "./components/layout";
import { SkeletonTheme } from "react-loading-skeleton";
import { HelmetProvider } from 'react-helmet-async';
import { theme } from "./theme";

const ContextsProvider = ({ children }: { children: ReactNode }) => (
  <SessionProvider>
    <ApiProvider>
      <AuthProvider>
        <DrawerProvider>{children}</DrawerProvider>
      </AuthProvider>
    </ApiProvider>
  </SessionProvider>
);

const TranslatedApp = () => {
  const language = getLocalStorageItem(LOCAL_STORAGE_KEYS.LANGUAGE);

  return (
    <TranslationsProvider
      language={(language as TranslationLanguage) || TranslationLanguage.pl}
    >
      <Pages />
    </TranslationsProvider>
  );
};

const App = () => (
  <HelmetProvider>
    <AppRoot>
      <SkeletonTheme
        baseColor={theme.skeleton.baseColor}
        highlightColor={theme.skeleton.highlightColor}
      >
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            dense
          >
            <ContextsProvider>
              <TranslatedApp />
            </ContextsProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </SkeletonTheme>
    </AppRoot>
  </HelmetProvider>
);

export default App;
