export enum TRACKING_PAYLOAD_ACTION_TYPES {
  click = "click",
  touch = "touch",
  clickAndMove = "clickAndMove",
  swipe = "swipe",
  scrollUp = "scrollUp",
  scrollDown = "scrollDown",
  keyboard = "keyboard",
  hover = "hover",
}
