import React from "react";
import { Container, Content } from "./styles";
import { useBlogHomepage } from "./useBlogHomepage";
import { ArticleList } from "../../../components/articleList";
import { Header } from "./header";

export const BlogHomepage = () => {
  const { topArticles } = useBlogHomepage();
  return (
    <Container>
      <Header />
      <Content>
        <ArticleList {...topArticles} />
      </Content>
    </Container>
  );
};
