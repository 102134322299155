import { useState } from "react";
import { InputProps } from "./types";

export const useInput = (inputProps: InputProps) => {
  const { value: defaultValue } = inputProps;

  const [value, setValue] = useState(defaultValue);
  const [isValid, setIsValid] = useState(true);

  const handleOnChange: InputProps["onChange"] = ({
    value: newValue,
    isValid: newIsValid,
  }) => {
    setValue(newValue);
    setIsValid(newIsValid);
  };

  return {
    value,
    isValid,
    inputProps: {
      ...inputProps,
      onChange: handleOnChange,
    },
  };
};
