import React from "react";
import {
  ArticleContentContainer,
  SubTitleContainer,
  Container,
  Content,
  BelowTitleContainer,
  Title,
  BelowTitleText,
} from "./styles";
import Skeleton from "react-loading-skeleton";
import { TableOfContentSkeleton } from "./tableOfContent/tableOfContent.skeleton";
import { ParagraphSkeleton } from "./articleComponents/paragraph";
import { ImageSkeleton } from "./articleComponents/image";
import { PlanListItemSkeleton } from "../../../components/plansList/planListItem/planListItem.skeleton";

export const ArticleSkeleton = () => {
  return (
    <Container>
      <Content>
        <Title>
          <Skeleton />
        </Title>
        <SubTitleContainer>
          <BelowTitleText>
            <Skeleton width={80} />
          </BelowTitleText>
          <BelowTitleText>
            <Skeleton width={100} />
          </BelowTitleText>
        </SubTitleContainer>
        <BelowTitleContainer>
          <ArticleContentContainer>
            <ParagraphSkeleton />
            <PlanListItemSkeleton />
            <ImageSkeleton />
          </ArticleContentContainer>
          <TableOfContentSkeleton />
        </BelowTitleContainer>
      </Content>
    </Container>
  );
};
