import { useContext, useEffect } from "react";
import { ApiDataContext, ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { useParams } from "react-router-dom";
import { FactsSummaryProps } from "./factsSummary";
import { ActionsProps } from "./actions";
import { ImageGalleryProps } from "./imageGallery";
import { MapPositionProps } from "./mapPosition";
import { PlacesListHorizontalProps } from "./placesListHorizontal";
import { AdministrativeAreasProps } from "./administrativeAreas";
import { Constants, Models } from "@tour-buddy/interfaces";
import { TranslationKey } from "@tour-buddy/ui-translations";

const mapPlacesListItem = (
  places: Models.Place[]
): PlacesListHorizontalProps["items"] =>
  places.map(({ id = "", name = "", images }) => ({
    name,
    imageUrl: images?.[0]?.thumbnailLink || images?.[0]?.link || "",
    id,
  }));

const mapAdministrativeAreaItem = (place?: Models.Place) =>
  place
    ? {
        id: place.id,
        name: place.name,
        imageUrl:
          place?.images?.[0]?.thumbnailLink || place?.images?.[0]?.link || "",
      }
    : undefined;

export const usePlaceDetails = () => {
  const { selectedPlace } = useContext(ApiDataContext);
  const { getSelectedPlace } = useContext(ApiRequestContext);
  const { placeId } = useParams();

  useEffect(() => {
    getSelectedPlace.request({
      variables: { placeId },
      fetchPolicy: "no-cache",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  const name = selectedPlace.name || "";
  const facts: FactsSummaryProps = {
    text: selectedPlace?.factsSummary || "",
  };
  const imageGallery: ImageGalleryProps = {
    images: (selectedPlace?.images || [])
      .filter(({ link }) => link)
      ?.map(({ link, alt }) => ({
        url: link || "",
        alt: alt || name,
      })),
  };
  const actions: ActionsProps = {
    wikipediaUrl:
      selectedPlace?.integration?.find(
        ({ type }) => type === Constants.PLACE_INTEGRATION_TYPES.wikipedia_pl
      )?.value || "",
  };
  const mapPosition: MapPositionProps = {
    latitude: selectedPlace?.location?.latitude,
    longitude: selectedPlace?.location?.longitude,
  };
  const administrativeAreas: AdministrativeAreasProps = {
    country: mapAdministrativeAreaItem(
      selectedPlace?.administrativeAreas?.country
    ),
    regionArea1: mapAdministrativeAreaItem(
      selectedPlace?.administrativeAreas?.regionArea1
    ),
    regionArea2: mapAdministrativeAreaItem(
      selectedPlace?.administrativeAreas?.regionArea2
    ),
    regionArea3: mapAdministrativeAreaItem(
      selectedPlace?.administrativeAreas?.regionArea3
    ),
    city: mapAdministrativeAreaItem(selectedPlace?.administrativeAreas?.city),
    district: mapAdministrativeAreaItem(
      selectedPlace?.administrativeAreas?.district
    ),
  };
  const childrenPlaces: PlacesListHorizontalProps = {
    items: mapPlacesListItem(selectedPlace.placeGroupChildren || []),
    header: {
      name: {
        translationKey:
          TranslationKey["main.placeDetails.childrenPlacesHeader"],
      },
    },
  };
  const parentPlaces: PlacesListHorizontalProps = {
    items: mapPlacesListItem(selectedPlace.placeGroupParents || []),
    header: {
      name: {
        translationKey: TranslationKey["main.placeDetails.parentPlacesHeader"],
      },
    },
  };

  const isLoading = !getSelectedPlace.isFinished;

  return {
    name,
    facts,
    actions,
    imageGallery,
    mapPosition,
    childrenPlaces,
    parentPlaces,
    administrativeAreas,
    isLoading,
  };
};
