import React from "react";
import { Container, DetailContainer } from "./styles";
import Skeleton from "react-loading-skeleton";

export const DayDetailsSkeleton = () => {
  return (
    <Container>
      <DetailContainer>
        <Skeleton width={24} height={24} />
        <Skeleton width={150} height={24} />
      </DetailContainer>
      <DetailContainer>
        <Skeleton width={24} height={24} />
        <Skeleton width={150} height={24} />
      </DetailContainer>
      <DetailContainer>
        <Skeleton width={24} height={24} />
        <Skeleton width={150} height={24} />
      </DetailContainer>
    </Container>
  );
};
