import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { AlertCircleIcon, CheckCircleIcon } from "../../../components/icons";
import { Colors } from "../../../types";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px - 56px);
`;

export const FinishStateContainer = styled("div")`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > * {
    text-align: center;
  }
`;

export const SuccessIcon = styled(CheckCircleIcon)`
  width: 64px;
  height: 64px;
  stroke: ${theme.colors[Colors.success].main};
`;

export const ErrorIcon = styled(AlertCircleIcon)`
  width: 64px;
  height: 64px;
  stroke: ${theme.colors[Colors.error].main};
`;

export const FormContainer = styled("div")`
  width: 350px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border-radius: ${theme.borderRadius.card}px;
  border: 1px solid ${theme.colors.gray.light};

  ${theme.mediaQueries.desktop} {
    padding: 24px 32px;
  }
`;

export const RegisterContainer = styled("div")`
  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 2px;
  }

  ${theme.mediaQueries.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
