export var TranslationKey;
(function (TranslationKey) {
    TranslationKey["main.landingPage.newsletter.header"] = "main.landingPage.newsletter.header";
    TranslationKey["main.landingPage.newsletter.h2"] = "main.landingPage.newsletter.h2";
    TranslationKey["main.landingPage.newsletter.input"] = "main.landingPage.newsletter.input";
    TranslationKey["main.landingPage.newsletter.inputLabel"] = "main.landingPage.newsletter.inputLabel";
    TranslationKey["main.landingPage.newsletter.button"] = "main.landingPage.newsletter.button";
    TranslationKey["main.landingPage.features.header"] = "main.landingPage.features.header";
    TranslationKey["main.landingPage.features.feature1Header"] = "main.landingPage.features.feature1Header";
    TranslationKey["main.landingPage.features.feature1Description"] = "main.landingPage.features.feature1Description";
    TranslationKey["main.landingPage.features.feature2Header"] = "main.landingPage.features.feature2Header";
    TranslationKey["main.landingPage.features.feature2Description"] = "main.landingPage.features.feature2Description";
    TranslationKey["main.landingPage.features.feature3Header"] = "main.landingPage.features.feature3Header";
    TranslationKey["main.landingPage.features.feature3Description"] = "main.landingPage.features.feature3Description";
    TranslationKey["main.landingPage.features.feature4Header"] = "main.landingPage.features.feature4Header";
    TranslationKey["main.landingPage.features.feature4Description"] = "main.landingPage.features.feature4Description";
    TranslationKey["main.landingPage.numbers.header"] = "main.landingPage.numbers.header";
    TranslationKey["main.landingPage.numbers.paragraph"] = "main.landingPage.numbers.paragraph";
    TranslationKey["main.landingPage.numbers.cityNumber"] = "main.landingPage.numbers.cityNumber";
    TranslationKey["main.landingPage.numbers.cityTitle"] = "main.landingPage.numbers.cityTitle";
    TranslationKey["main.landingPage.numbers.countryNumber"] = "main.landingPage.numbers.countryNumber";
    TranslationKey["main.landingPage.numbers.countryTitle"] = "main.landingPage.numbers.countryTitle";
    TranslationKey["main.landingPage.numbers.placesNumber"] = "main.landingPage.numbers.placesNumber";
    TranslationKey["main.landingPage.numbers.placesTitle"] = "main.landingPage.numbers.placesTitle";
    TranslationKey["main.landingPage.main.header"] = "main.landingPage.main.header";
    TranslationKey["main.landingPage.main.description"] = "main.landingPage.main.description";
    TranslationKey["main.registerAccount.title"] = "main.registerAccount.title";
    TranslationKey["main.registerAccount.submit"] = "main.registerAccount.submit";
    TranslationKey["main.registerAccount.loaderCreatingUser"] = "main.registerAccount.loaderCreatingUser";
    TranslationKey["main.registerAccount.loginRedirectText"] = "main.registerAccount.loginRedirectText";
    TranslationKey["main.registerAccount.loginRedirectButton"] = "main.registerAccount.loginRedirectButton";
    TranslationKey["main.registerAccount.passwordRequirements"] = "main.registerAccount.passwordRequirements";
    TranslationKey["main.registerAccount.successfulMessage"] = "main.registerAccount.successfulMessage";
    TranslationKey["main.registerAccount.errorMessage"] = "main.registerAccount.errorMessage";
    TranslationKey["main.registerAccount.confirmPolicyTermsAndCondition"] = "main.registerAccount.confirmPolicyTermsAndCondition";
    TranslationKey["main.login.title"] = "main.login.title";
    TranslationKey["main.login.submit"] = "main.login.submit";
    TranslationKey["main.login.registerRedirectText"] = "main.login.registerRedirectText";
    TranslationKey["main.login.registerRedirectButton"] = "main.login.registerRedirectButton";
    TranslationKey["main.login.registerForgotPassword"] = "main.login.registerForgotPassword";
    TranslationKey["main.verifyAccount.title"] = "main.verifyAccount.title";
    TranslationKey["main.verifyAccount.waiting"] = "main.verifyAccount.waiting";
    TranslationKey["main.verifyAccount.error"] = "main.verifyAccount.error";
    TranslationKey["main.verifyAccount.success"] = "main.verifyAccount.success";
    TranslationKey["main.verifyAccountModal.title"] = "main.verifyAccountModal.title";
    TranslationKey["main.verifyAccountModal.message"] = "main.verifyAccountModal.message";
    TranslationKey["main.verifyAccountModal.backButton"] = "main.verifyAccountModal.backButton";
    TranslationKey["main.verifyAccountModal.sendVerificationButton"] = "main.verifyAccountModal.sendVerificationButton";
    TranslationKey["main.forgotPassword.title"] = "main.forgotPassword.title";
    TranslationKey["main.forgotPassword.successfulMessage"] = "main.forgotPassword.successfulMessage";
    TranslationKey["main.forgotPassword.errorMessage"] = "main.forgotPassword.errorMessage";
    TranslationKey["main.forgotPassword.loginRedirectText"] = "main.forgotPassword.loginRedirectText";
    TranslationKey["main.forgotPassword.loginRedirectButton"] = "main.forgotPassword.loginRedirectButton";
    TranslationKey["main.forgotPassword.submit"] = "main.forgotPassword.submit";
    TranslationKey["main.forgotPassword.loader"] = "main.forgotPassword.loader";
    TranslationKey["main.resetPassword.title"] = "main.resetPassword.title";
    TranslationKey["main.resetPassword.submit"] = "main.resetPassword.submit";
    TranslationKey["main.resetPassword.loaderUpdatingPassword"] = "main.resetPassword.loaderUpdatingPassword";
    TranslationKey["main.resetPassword.successMessage"] = "main.resetPassword.successMessage";
    TranslationKey["main.resetPassword.errorMessage"] = "main.resetPassword.errorMessage";
    TranslationKey["main.userProfile.title"] = "main.userProfile.title";
    TranslationKey["main.userPlans.header"] = "main.userPlans.header";
    TranslationKey["main.userPlans.availableTime"] = "main.userPlans.availableTime";
    TranslationKey["main.userPlans.availableDays"] = "main.userPlans.availableDays";
    TranslationKey["main.userPlans.placesNumber"] = "main.userPlans.placesNumber";
    TranslationKey["main.userPlans.locationSingle"] = "main.userPlans.locationSingle";
    TranslationKey["main.userPlans.locationMultiple"] = "main.userPlans.locationMultiple";
    TranslationKey["main.userPlans.planDetailsButton"] = "main.userPlans.planDetailsButton";
    TranslationKey["main.userPlans.removeAccountConfirmHeader"] = "main.userPlans.removeAccountConfirmHeader";
    TranslationKey["main.places.header"] = "main.places.header";
    TranslationKey["main.places.showAll"] = "main.places.showAll";
    TranslationKey["main.plan.loadingPlan"] = "main.plan.loadingPlan";
    TranslationKey["main.plan.calculatingRoutes"] = "main.plan.calculatingRoutes";
    TranslationKey["main.plan.loadingPlace"] = "main.plan.loadingPlace";
    TranslationKey["main.plan.placesListHeader"] = "main.plan.placesListHeader";
    TranslationKey["main.plan.placeDetailsButton"] = "main.plan.placeDetailsButton";
    TranslationKey["main.plan.addPlan"] = "main.plan.addPlan";
    TranslationKey["main.main.nearbyPlacesHeader"] = "main.main.nearbyPlacesHeader";
    TranslationKey["main.main.popularPlacesHeader"] = "main.main.popularPlacesHeader";
    TranslationKey["main.main.topArticles.header"] = "main.main.topArticles.header";
    TranslationKey["main.main.topArticles.showAll"] = "main.main.topArticles.showAll";
    TranslationKey["main.placeDetails.scoreOverallHeader"] = "main.placeDetails.scoreOverallHeader";
    TranslationKey["main.placeDetails.scoreOverallDescription"] = "main.placeDetails.scoreOverallDescription";
    TranslationKey["main.placeDetails.dataHeader"] = "main.placeDetails.dataHeader";
    TranslationKey["main.placeDetails.dataDescription"] = "main.placeDetails.dataDescription";
    TranslationKey["main.placeDetails.childrenPlacesHeader"] = "main.placeDetails.childrenPlacesHeader";
    TranslationKey["main.placeDetails.parentPlacesHeader"] = "main.placeDetails.parentPlacesHeader";
    TranslationKey["main.placeDetails.factsHeader"] = "main.placeDetails.factsHeader";
    TranslationKey["main.articles.estimatedReadingTime"] = "main.articles.estimatedReadingTime";
    TranslationKey["main.articles.placeRedirect"] = "main.articles.placeRedirect";
    TranslationKey["main.articles.planRedirect"] = "main.articles.planRedirect";
    TranslationKey["main.drawer.button.main"] = "main.drawer.button.main";
    TranslationKey["main.drawer.button.map"] = "main.drawer.button.map";
    TranslationKey["main.drawer.button.blog"] = "main.drawer.button.blog";
    TranslationKey["main.drawer.button.userPlans"] = "main.drawer.button.userPlans";
    TranslationKey["main.drawer.button.userProfile"] = "main.drawer.button.userProfile";
    TranslationKey["main.drawer.button.articles"] = "main.drawer.button.articles";
    TranslationKey["main.drawer.language.header"] = "main.drawer.language.header";
    TranslationKey["main.input.label.email"] = "main.input.label.email";
    TranslationKey["main.input.label.password"] = "main.input.label.password";
    TranslationKey["main.input.label.oldPassword"] = "main.input.label.oldPassword";
    TranslationKey["main.input.label.newPassword"] = "main.input.label.newPassword";
    TranslationKey["main.input.label.confirmPassword"] = "main.input.label.confirmPassword";
    TranslationKey["main.input.label.firstName"] = "main.input.label.firstName";
    TranslationKey["main.input.label.lastName"] = "main.input.label.lastName";
    TranslationKey["main.input.label.availableDays"] = "main.input.label.availableDays";
    TranslationKey["main.input.label.availableTimePerDay"] = "main.input.label.availableTimePerDay";
    TranslationKey["main.input.label.availableTimeSpecificSwitch"] = "main.input.label.availableTimeSpecificSwitch";
    TranslationKey["main.input.label.availableTime"] = "main.input.label.availableTime";
    TranslationKey["main.input.label.startPlace"] = "main.input.label.startPlace";
    TranslationKey["main.input.label.finishPlace"] = "main.input.label.finishPlace";
    TranslationKey["main.input.label.sameStartFinishPlace"] = "main.input.label.sameStartFinishPlace";
    TranslationKey["main.input.label.specifiedPlacePerDay"] = "main.input.label.specifiedPlacePerDay";
    TranslationKey["main.input.label.search"] = "main.input.label.search";
    TranslationKey["main.button.showMore"] = "main.button.showMore";
    TranslationKey["main.button.logout"] = "main.button.logout";
    TranslationKey["main.button.login"] = "main.button.login";
    TranslationKey["main.button.register"] = "main.button.register";
    TranslationKey["main.button.verifyAccount"] = "main.button.verifyAccount";
    TranslationKey["main.button.changePassword"] = "main.button.changePassword";
    TranslationKey["main.button.removeAccount"] = "main.button.removeAccount";
    TranslationKey["main.button.removeAccountConfirm"] = "main.button.removeAccountConfirm";
    TranslationKey["main.button.removeAccountCancel"] = "main.button.removeAccountCancel";
    TranslationKey["main.button.goBackHome"] = "main.button.goBackHome";
    TranslationKey["main.button.goBackPlaceList"] = "main.button.goBackPlaceList";
    TranslationKey["main.button.goBackUserPlanList"] = "main.button.goBackUserPlanList";
    TranslationKey["main.button.goBackPlan"] = "main.button.goBackPlan";
    TranslationKey["main.button.renamePlan"] = "main.button.renamePlan";
    TranslationKey["main.button.removePlan"] = "main.button.removePlan";
    TranslationKey["main.button.readMore"] = "main.button.readMore";
    TranslationKey["main.button.readLess"] = "main.button.readLess";
    TranslationKey["main.button.showOnMap"] = "main.button.showOnMap";
    TranslationKey["main.button.refresh"] = "main.button.refresh";
    TranslationKey["main.button.planCreate"] = "main.button.planCreate";
    TranslationKey["main.button.showAdvancedFilters"] = "main.button.showAdvancedFilters";
    TranslationKey["main.button.planEdit"] = "main.button.planEdit";
    TranslationKey["main.button.close"] = "main.button.close";
    TranslationKey["main.button.showAll"] = "main.button.showAll";
    TranslationKey["main.button.back"] = "main.button.back";
    TranslationKey["main.button.next"] = "main.button.next";
    TranslationKey["main.tag.tourism"] = "main.tag.tourism";
    TranslationKey["main.tag.architecture"] = "main.tag.architecture";
    TranslationKey["main.tag.park"] = "main.tag.park";
    TranslationKey["main.tag.historic"] = "main.tag.historic";
    TranslationKey["main.tag.facility"] = "main.tag.facility";
    TranslationKey["main.tag.education"] = "main.tag.education";
    TranslationKey["main.tag.commercial"] = "main.tag.commercial";
    TranslationKey["main.tag.industrial"] = "main.tag.industrial";
    TranslationKey["main.tag.neighborhood"] = "main.tag.neighborhood";
    TranslationKey["main.tag.city"] = "main.tag.city";
    TranslationKey["main.tag.natural"] = "main.tag.natural";
    TranslationKey["main.tag.square"] = "main.tag.square";
    TranslationKey["main.tag.street"] = "main.tag.street";
    TranslationKey["main.tag.station"] = "main.tag.station";
    TranslationKey["main.tag.cinema"] = "main.tag.cinema";
    TranslationKey["main.tag.theatre"] = "main.tag.theatre";
    TranslationKey["main.tag.library"] = "main.tag.library";
    TranslationKey["main.tag.bridge"] = "main.tag.bridge";
    TranslationKey["main.error.userExists"] = "main.error.userExists";
    TranslationKey["main.error.emailFormat"] = "main.error.emailFormat";
    TranslationKey["main.error.passwordRequirements"] = "main.error.passwordRequirements";
    TranslationKey["main.error.passwordNotMatch"] = "main.error.passwordNotMatch";
    TranslationKey["main.error.missingEmail"] = "main.error.missingEmail";
    TranslationKey["main.error.missingFirstName"] = "main.error.missingFirstName";
    TranslationKey["main.error.missingLastName"] = "main.error.missingLastName";
    TranslationKey["main.error.missingPassword"] = "main.error.missingPassword";
    TranslationKey["main.error.cannotLogin"] = "main.error.cannotLogin";
    TranslationKey["main.error.cannotCreateUser"] = "main.error.cannotCreateUser";
    TranslationKey["main.error.cannotUpdatePassword"] = "main.error.cannotUpdatePassword";
})(TranslationKey || (TranslationKey = {}));
export var TranslationLanguage;
(function (TranslationLanguage) {
    TranslationLanguage["en"] = "en";
    TranslationLanguage["pl"] = "pl";
})(TranslationLanguage || (TranslationLanguage = {}));
