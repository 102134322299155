import styled from "@emotion/styled";
import { theme } from "../../../../theme";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${theme.mediaQueries.desktop} {
    flex-direction: row;
  }
`;
