import styled from "@emotion/styled";
import { theme } from "../../../theme";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled("h1")`
  color: ${theme.colors.text.main};
  font-size: ${theme.font.sizes.h1}px;
  font-weight: ${theme.font.fontWeights.bold};
`;

export const BelowTitleText = styled("div")`
  color: ${theme.colors.text.main};
  font-size: ${theme.font.sizes.body2}px;
  font-weight: ${theme.font.fontWeights.regular};
`;
export const ArticleContentContainer = styled("div")`
  gap: 16px;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  width: 100%;
`;

export const BelowTitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
`;

export const SubTitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 32px;
`;

export const Content = styled("div")`
  width: calc(100% - 64px);
  max-width: 1200px;
  padding: 32px;

  @media (max-width: 768px) {
    width: calc(100% - 16px);
    padding: 32px 8px;
  }
`;
