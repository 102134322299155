import { StyledAlertCircleIcon } from "./styles";
import React from "react";
import { Button, Variants } from "../../../components/button";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { useVerifyAccount } from "./useVerifyAccount";
import { TextStatic } from "../../../components/text";

export const ContentError = ({
  handleGoBackClick,
}: ReturnType<typeof useVerifyAccount>["errorProps"]) => (
  <>
    <StyledAlertCircleIcon />
    <TextStatic
      translationKey={TranslationKey["main.verifyAccount.title"]}
      variant="h1"
    />
    <TextStatic
      translationKey={TranslationKey["main.verifyAccount.error"]}
      variant="body1"
    />
    <Button
      variant={Variants.contained}
      onClick={handleGoBackClick}
      text={{ translationKey: TranslationKey["main.button.back"] }}
    />
  </>
);
