import { ValidationSchema } from "@tour-buddy/validation";

export const INPUT_EMAIL_VALIDATION: ValidationSchema[] = [
  {
    type: "string",
    required: true,
    message: "Missing email",
    errorCode: "missingEmail",
  },
  {
    type: "string",
    pattern:
      '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    message: "Wrong email format",
    errorCode: "emailFormat",
  },
];
