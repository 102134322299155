import React from "react";
import { Container } from "./styles";
import Skeleton from "react-loading-skeleton";

export const ActionsSkeleton = () => {
  return (
    <Container>
      <Skeleton height={48} width={180} />
    </Container>
  );
};
