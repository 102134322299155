import { useInput } from "../../../components/input";
import { INPUT_PASSWORD_VALIDATION } from "./constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { pageUrls } from "../../urls";
import { PageState } from "./types";

const getPageState = (
  isSuccess: boolean,
  isRequestFinished: boolean,
  forgotPasswordToken: string
): PageState => {
  if (!forgotPasswordToken) return PageState.error;

  if (isRequestFinished) {
    return isSuccess ? PageState.success : PageState.error;
  }
  return PageState.form;
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const { resetPassword } = useContext(ApiRequestContext);
  const [searchParams] = useSearchParams();
  const passwordInput = useInput({
    validationSchema: INPUT_PASSWORD_VALIDATION,
  });
  const confirmPasswordInput = useInput({
    validationSchema: INPUT_PASSWORD_VALIDATION,
  });
  const forgotPasswordToken = searchParams.get("token") || "";

  const isValid =
    passwordInput.isValid &&
    confirmPasswordInput.isValid &&
    passwordInput.value === confirmPasswordInput.value;
  const isRequestFinished = resetPassword.isFinished;
  const isSuccess = resetPassword.data?.statusCode === 200;
  const pageState = getPageState(
    isSuccess,
    isRequestFinished,
    forgotPasswordToken
  );

  const handleOnSubmit = () => {
    if (isValid) {
      resetPassword.request({
        variables: {
          password: passwordInput.value,
          forgotPasswordToken,
        },
      });
    }
  };

  const handleOnLoginRedirect = () => {
    navigate(pageUrls.login());
  };

  const handleOnBack = () => {
    navigate(pageUrls.homepage());
  };

  const formProps = {
    handleOnSubmit,
    handleOnLoginRedirect,
    passwordInput,
    confirmPasswordInput,
    isValid,
  };

  const successProps = {
    handleOnBack,
    handleOnLoginRedirect,
  };

  const errorProps = {
    handleOnBack,
  };

  return {
    formProps,
    successProps,
    errorProps,
    pageState,
  };
};
