import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled("div")`
  width: calc(100% - 64px);
  max-width: 1200px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 32px 0;
  }
`;

export const DetailsContainer = styled("div")`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 768px;
  width: 100%;
  gap: 16px;
`;

export const PlacesContainer = styled("div")`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 16px;
`;
