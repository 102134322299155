import { useForgotPassword } from "./useForgotPassword";
import { ButtonContainer, FinishStateContainer, ErrorIcon } from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Button } from "../../../components/button";
import React from "react";

export const ForgotPasswordError = ({
  handleOnBack,
}: ReturnType<typeof useForgotPassword>["errorProps"]) => (
  <FinishStateContainer>
    <ErrorIcon />
    <TextStatic
      translationKey={TranslationKey["main.registerAccount.title"]}
      variant="h1"
    />
    <TextStatic
      translationKey={TranslationKey["main.error.cannotUpdatePassword"]}
      variant="body1"
    />
    <ButtonContainer>
      <Button
        onClick={handleOnBack}
        text={{ translationKey: TranslationKey["main.button.back"] }}
      />
    </ButtonContainer>
  </FinishStateContainer>
);
