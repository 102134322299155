import React from "react";
import { IconProps } from "./types";

export const ArrowRightIcon = ({ className, style }: IconProps) => (
  <svg
    style={style}
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
  >
    <path
      d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
