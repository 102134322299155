import React from "react";
import { HeaderProps } from "./types";
import { Container, Content } from "./styles";
import { TextStatic } from "../../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";

export const Header: React.FC<HeaderProps> = () => {
  return (
    <Container>
      <Content>
        <TextStatic
          translationKey={TranslationKey["main.landingPage.main.header"]}
          variant="h1"
        />
        <TextStatic
          translationKey={TranslationKey["main.landingPage.main.description"]}
          variant="h2"
        />
      </Content>
    </Container>
  );
};
