import { Common } from "@tour-buddy/interfaces";

export class HttpError extends Error implements Common.IHttpError {
  statusCode = 0;
  message = "";
  type = "";
  error = null;

  constructor(params: Common.IHttpError) {
    super(params.message);

    this.statusCode = params.statusCode;
    this.message = params.message;
    this.type = params.type;
    this.error = params.error;
  }
}
