import styled from "@emotion/styled";
import { theme } from "../../../../theme";
import { Colors } from "../../../../types";

export const Content = styled("div")`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding: 64px 32px;
  height: 150px;
`;

export const Container = styled("div")`
  width: 100%;
  background-color: ${theme.colors[Colors.primary].light};
  display: flex;
  justify-content: center;
  align-items: center;
`;
