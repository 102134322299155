import React from "react";
import { Container } from "./styles";
import { useCreateAccount } from "./useCreateAccount";
import { PageState } from "./types";
import { CreateAccountSuccess } from "./contentSuccess";
import { CreateAccountError } from "./contentError";
import { CreateAccountForm } from "./contentForm";

export const CreateAccount = () => {
  const { pageState, formProps, successProps, errorProps } = useCreateAccount();

  return (
    <Container>
      {pageState === PageState.success && (
        <CreateAccountSuccess {...successProps} />
      )}
      {pageState === PageState.error && <CreateAccountError {...errorProps} />}
      {pageState === PageState.form && <CreateAccountForm {...formProps} />}
    </Container>
  );
};
