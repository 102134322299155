import { ValidationSchema } from "@tour-buddy/validation";

export const INPUT_FIRST_NAME_VALIDATION: ValidationSchema[] = [
  {
    type: "string",
    required: true,
    message: "Missing first name",
    errorCode: "missingFirstName",
  },
];

export const INPUT_LAST_NAME_VALIDATION: ValidationSchema[] = [
  {
    type: "string",
    required: true,
    message: "Missing last name",
    errorCode: "missingLastName",
  },
];

export const INPUT_EMAIL_VALIDATION: ValidationSchema[] = [
  {
    type: "string",
    required: true,
    message: "Missing email",
    errorCode: "missingEmail",
  },
  {
    type: "string",
    pattern:
      '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    message: "Wrong email format",
    errorCode: "emailFormat",
  },
];

export const INPUT_PASSWORD_VALIDATION: ValidationSchema[] = [
  {
    type: "string",
    required: true,
    message: "Missing password",
    errorCode: "missingPassword",
  },
  {
    type: "string",
    pattern: "[0-9]+",
    message: "At least one number",
    errorCode: "passwordRequirements",
  },
  {
    type: "string",
    pattern: "[A-Z]+",
    message: "At least one capital letter",
    errorCode: "passwordRequirements",
  },
  {
    type: "string",
    pattern: "[a-z]+",
    message: "At least one lowercase letter",
    errorCode: "passwordRequirements",
  },
  {
    type: "string",
    minLength: 8,
    message: "At least 8 characters",
    errorCode: "passwordRequirements",
  },
];
