import React from "react";
import { Container, Content, DetailsContainer } from "./styles";
import { Text } from "../../../components/text";
import { usePlaceDetails } from "./usePlaceDetails";
import { ImageGallery } from "./imageGallery";
import { Actions } from "./actions";
import { AdministrativeAreas } from "./administrativeAreas";
import { MapPosition } from "./mapPosition";
import { PlacesListHorizontal } from "./placesListHorizontal";
import { FactsSummary } from "./factsSummary";
import { PlaceDetailsSkeleton } from "./placeDetails.skeleton";

export const PlaceDetails = () => {
  const {
    name,
    childrenPlaces,
    parentPlaces,
    facts,
    mapPosition,
    imageGallery,
    actions,
    administrativeAreas,
    isLoading,
  } = usePlaceDetails();

  const showActions = !!actions.wikipediaUrl;

  return isLoading ? (
    <PlaceDetailsSkeleton />
  ) : (
    <Container>
      <Content>
        <DetailsContainer>
          <Text text={name} variant="h1" />
          {!!imageGallery.images.length && <ImageGallery {...imageGallery} />}
          {showActions && <Actions {...actions} />}
          {facts.text && <FactsSummary {...facts} />}
          <AdministrativeAreas {...administrativeAreas} />
          <MapPosition {...mapPosition} />
          {!!childrenPlaces.items.length && (
            <PlacesListHorizontal {...childrenPlaces} />
          )}
          {!!parentPlaces.items.length && (
            <PlacesListHorizontal {...parentPlaces} />
          )}
        </DetailsContainer>
      </Content>
    </Container>
  );
};
