import React from "react";
import { Container, HeaderContainer, ListContainer } from "./styles";
import { ArticleListProps } from "./types";
import { ArticleListItem } from "./articleListItem";
import { ArticleListItemSkeleton } from "./articleListItem.skeleton";
import { TextStatic } from "../text";
import { Button } from "../button";

export const ArticleList = ({
  articles = [],
  header,
  isLoading = false,
  loadingItemsCount = 1,
}: ArticleListProps) => {
  const { text, actionClick, actionText } = header || {};

  const handleOnClick = () => {
    actionClick?.();
  };

  return (
    <Container>
      {text && (
        <HeaderContainer>
          <TextStatic translationKey={text} variant="h2" />
          {actionText && actionClick && (
            <Button
              onClick={handleOnClick}
              text={{ translationKey: actionText }}
            />
          )}
        </HeaderContainer>
      )}
      <ListContainer>
        {isLoading
          ? Array(loadingItemsCount)
              .fill(0)
              .map((_, idx) => <ArticleListItemSkeleton key={idx} />)
          : articles.map((article, idx) => (
              <ArticleListItem {...article} key={idx} />
            ))}
      </ListContainer>
    </Container>
  );
};
