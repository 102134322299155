import React from "react";
import { DynamicHeadTagsProps } from "./types";
import { Helmet } from "react-helmet-async";

const DEFAULT_TITLE = 'Wizytor';
const DEFAULT_DESCRIPTION = 'Personalized tour guide';

export const DynamicHeadTags: React.FC<DynamicHeadTagsProps> = ({ title = DEFAULT_TITLE, description = DEFAULT_DESCRIPTION, scripts = [] }) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      {scripts.map((script) => (script))}
    </Helmet>
  );
};
