import { useResetPassword } from "./useResetPassword";
import { ButtonContainer, FinishStateContainer, SuccessIcon } from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Button, Variants } from "../../../components/button";
import React from "react";

export const ResetPasswordSuccess = ({
  handleOnBack,
  handleOnLoginRedirect,
}: ReturnType<typeof useResetPassword>["successProps"]) => (
  <FinishStateContainer>
    <SuccessIcon />
    <TextStatic
      translationKey={TranslationKey["main.resetPassword.title"]}
      variant="h1"
    />
    <TextStatic
      translationKey={TranslationKey["main.resetPassword.successMessage"]}
      variant="body1"
    />
    <ButtonContainer>
      <Button
        onClick={handleOnLoginRedirect}
        text={{ translationKey: TranslationKey["main.button.login"] }}
      />
      <Button
        onClick={handleOnBack}
        text={{ translationKey: TranslationKey["main.button.back"] }}
        variant={Variants.outlined}
      />
    </ButtonContainer>
  </FinishStateContainer>
);
