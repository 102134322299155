import React from "react";
import { Container } from "./styles";
import { useResetPassword } from "./useResetPassword";
import { PageState } from "./types";
import { ResetPasswordSuccess } from "./contentSuccess";
import { ResetPasswordForm } from "./contentForm";
import { ResetPasswordError } from "./contentError";

export const ResetPassword = () => {
  const { errorProps, successProps, formProps, pageState } = useResetPassword();

  return (
    <Container>
      {pageState === PageState.success && (
        <ResetPasswordSuccess {...successProps} />
      )}
      {pageState === PageState.error && <ResetPasswordError {...errorProps} />}
      {pageState === PageState.form && <ResetPasswordForm {...formProps} />}
    </Container>
  );
};
