import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled("div")`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 32px 0;
  }
`;

export const Row = styled("div")<{ forceRow?: boolean }>`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: ${({ forceRow }) => (forceRow ? "row" : "column")};
    align-items: center;
  }
`;

export const Column = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Section = styled(Column)<{ isColorBackground?: boolean }>`
  padding: 32px 16px;
  position: relative;
  background-color: ${({ isColorBackground }) =>
    isColorBackground ? "#FFEBEB" : "white"};
  width: 100%;
  align-items: center;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export const MainContainer = styled(Row)`
  padding: 32px 0 64px;
  align-items: center;
`;

export const MainTextContainer = styled(Column)`
  margin-right: 16px;
  flex: 1;
  gap: 32px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

export const MainImageContainer = styled("div")`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const MainAppImage = styled("img")`
  max-width: 400px;
  max-height: 700px;
  width: auto;
  height: auto;

  @media (max-width: 420px) {
    max-width: 200px;
  }
`;

export const StoreImg = styled("img")`
  max-width: 200px;
  max-height: 48px;
  width: auto;
  height: auto;
  margin-right: 16px;
`;

export const NumberList = styled(Row)`
  justify-content: space-around;
  width: 100%;

  @media (max-width: 768px) {
    gap: 64px;
  }
`;

export const NumberGroup = styled(Column)`
  width: 200px;
  align-items: center;
`;

export const NumberValue = styled("div")`
  font-size: 60px;
  font-weight: 600;
  line-height: 1;
`;

export const NumberText = styled("div")`
  font-size: 40px;
`;
export const NewsletterInput = styled("input")`
  & input,
  & > div {
    max-height: 56px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const FeatureRow = styled(Row)`
  gap: 64px;
`;

export const FeatureColumn = styled(Column)`
  gap: 64px;
`;

export const FeatureGroup = styled(Column)`
  flex: 1;
`;
