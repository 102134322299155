import React from "react";
import { RightContainer, ItemContainer } from "./styles";
import Skeleton from "react-loading-skeleton";

export const PlaceListItemSkeleton = () => {
  return (
    <ItemContainer>
      <Skeleton height={150} width={150} style={{ lineHeight: "normal" }} />
      <RightContainer>
        <Skeleton height={32} width={150} />
        <Skeleton height={48} width={150} />
      </RightContainer>
    </ItemContainer>
  );
};
