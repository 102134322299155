import styled from "@emotion/styled";
import { theme } from "../../../../theme";

export const ParagraphContainer = styled("div")``;

export const ParagraphText = styled("p")`
  color: ${theme.colors.text.main};
  font-size: ${theme.font.sizes.body1}px;
  margin: 16px 0;
`;

export const TitleText = styled("h2")`
  color: ${theme.colors.text.main};
  font-size: ${theme.font.sizes.h2}px;
  font-weight: ${theme.font.fontWeights.semiBold};
  margin: 16px 0;
`;

export const TitleContainer = styled("div")``;

export const ImageContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  & > span {
    width: 100%;
  }
`;

export const ImageStyled = styled("img")`
  max-width: 100%;
  max-height: 600px;
  width: auto;
  height: auto;

  @media (max-width: 768px) {
    max-height: 400px;
  }
`;
