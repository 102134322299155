import styled from "@emotion/styled";
import { theme } from "../../../../../theme";

export const ItemContainer = styled("div")`
  display: flex;
  flex-direction: row;
  max-width: 768px;
  border: 1px solid ${theme.colors.gray.light};
  border-radius: ${theme.borderRadius.card}px;
  cursor: pointer;
`;

export const RightContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  overflow-x: hidden;
`;

export const Image = styled("img")`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: ${theme.borderRadius.card}px;
  border-bottom-left-radius: ${theme.borderRadius.card}px;
`;
