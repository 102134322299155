import React from "react";
import { Container, Content } from "./styles";
import { usePlansList } from "./usePlansList";
import { PlansList } from "../../../components/plansList";

export const PlansListPage = () => {
  const { userPlansList, isLoading } = usePlansList();
  return (
    <Container>
      <Content>
        <PlansList
          userPlans={userPlansList}
          isLoading={isLoading}
          loadingItemsCount={5}
        />
      </Content>
    </Container>
  );
};
