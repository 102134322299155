import styled from "@emotion/styled";
import { theme } from "../../../theme";
import { Colors } from "../../../types";

export const Container = styled("div")`
  height: 48px;
  width: calc(100% - 8px);
  padding: 4px;
  background-color: white;
  border-top: 5px solid ${theme.colors[Colors.primary].main};
  color: ${theme.colors.text.main};
  box-shadow: 0px 4px 12px 0px rgba(66, 68, 90, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.mediaQueries.desktop} {
    padding: 4px 16px;
    width: calc(100% - 32px);
  }
`;
