import { HttpError } from "./HttpError";
import { Common } from "@tour-buddy/interfaces";

export class ForbiddenAccessError extends HttpError {
  constructor(params?: Partial<Pick<Common.IHttpError, "message" | "error">>) {
    super({
      statusCode: 403,
      type: "FORBIDDEN_ACCESS_ERROR",
      message: "User has no access",
      error: params,
    });
  }
}
