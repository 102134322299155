import React from "react";
import { TableOfContentProps } from "./types";
import { Container, Title } from "./styles";
import { Button, Colors, Variants } from "../../../../components/button";

export const TableOfContent: React.FC<TableOfContentProps> = ({
  header,
  items,
}) => {
  const handleOnClick = (link: string) => () => {
    document
      .getElementById(link)
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
  };

  return (
    <Container>
      <Title>{header}:</Title>
      {items.map(({ text, link }, index) => (
        <Button
          align="left"
          isFullWidth
          key={link}
          text={{ text: `${index + 1}. ${text}` }}
          variant={Variants.text}
          color={Colors.text}
          onClick={handleOnClick(link)}
        />
      ))}
    </Container>
  );
};
