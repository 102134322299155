import { TextStaticOptionalProps } from "../text";

export enum Variants {
  contained = "contained",
  outlined = "outlined",
  text = "text",
}

export enum Colors {
  primary = "primary",
  secondary = "secondary",
  text = "text",
  info = "info",
}

export enum Size {
  tiny = "tiny",
  small = "small",
  medium = "medium",
  large = "large",
}

export interface ButtonProps {
  variant?: Variants;
  color?: Colors;
  size?: Size;
  text?: Pick<
    TextStaticOptionalProps,
    "text" | "translationInputMap" | "translationKey"
  >;
  Icon?: React.ReactNode;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  align?: "left" | "center" | "right";
  onClick: () => void;
}
