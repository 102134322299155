import React from "react";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { DaySelectorProps } from "./types";
import { Text, TextStatic } from "../../../../components/text";
import { Container, TimeIcon, AddressIcon, DetailContainer } from "./styles";

export const DayDetails = ({
  startAddress,
  finishAddress,
  availableTime,
}: DaySelectorProps) => {
  const hasTime = availableTime?.hours && availableTime?.minutes;
  const hasData = hasTime || startAddress || finishAddress;

  return hasData ? (
    <Container>
      {startAddress && (
        <DetailContainer>
          <AddressIcon />
          <Text text={startAddress} variant="body1" />
        </DetailContainer>
      )}
      {hasTime && (
        <DetailContainer>
          <TimeIcon />
          <TextStatic
            translationKey={TranslationKey["main.userPlans.availableTime"]}
            translationInputMap={{
              hours: `${availableTime?.hours || 0}`,
              minutes: `${availableTime?.minutes || 0}`,
            }}
            variant="body1"
          />
        </DetailContainer>
      )}
      {finishAddress && (
        <DetailContainer>
          <AddressIcon />
          <Text text={finishAddress} variant="body1" />
        </DetailContainer>
      )}
    </Container>
  ) : null;
};
