import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useApiRequestContext } from "@tour-buddy/ui-api-provider";
import { pageUrls } from "../../urls";
import { PageState } from "./types";

const getPageState = (
  isSuccess: boolean,
  isRequestFinished: boolean
): PageState => {
  if (isRequestFinished) {
    return isSuccess ? PageState.success : PageState.error;
  }
  return PageState.validation;
};

export const useVerifyAccount = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { verifyUser } = useApiRequestContext();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      verifyUser.request({
        variables: {
          token,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const isSuccess = verifyUser.data?.statusCode === 200;
  const isRequestFinished = verifyUser.isFinished;

  const handleLoginClick = () => {
    navigate(pageUrls.login());
  };

  const handleGoBackClick = () => {
    navigate(pageUrls.homepage());
  };

  const pageState = getPageState(isSuccess, isRequestFinished);

  const validationProps = {};

  const successProps = {
    handleLoginClick,
    handleGoBackClick,
  };

  const errorProps = {
    handleGoBackClick,
  };

  return {
    pageState,
    validationProps,
    successProps,
    errorProps,
  };
};
