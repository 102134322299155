import styled from "@emotion/styled";
import { theme } from "../../../theme";

export const ItemContainer = styled("div")`
  display: flex;
  flex-direction: row;
  max-width: 768px;
  width: 100%;
  border: 1px solid ${theme.colors.gray.light};
  border-radius: ${theme.borderRadius.card}px;
  cursor: pointer;
`;

export const RightContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  overflow-x: hidden;
`;

export const DetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Row = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const ImagesContainer = styled("div")`
  width: 200px;
  height: 200px;
  background-color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  border-bottom-left-radius: ${theme.borderRadius.card}px;
  border-top-left-radius: ${theme.borderRadius.card}px;
`;

export const Image = styled("img")<{
  width: number;
  height: number;
  topLeftRadius: boolean;
  bottomLeftRadius: boolean;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  object-fit: cover;
  border-top-left-radius: ${({ topLeftRadius }) =>
    topLeftRadius ? `${theme.borderRadius.card}px` : ""};
  border-bottom-left-radius: ${({ bottomLeftRadius }) =>
    bottomLeftRadius ? `${theme.borderRadius.card}px` : ""};
`;
