import styled from "@emotion/styled";
import { theme } from "../../theme";

export const TextH1 = styled("h1")`
  font-size: ${theme.font.sizes.h1}px;
  margin: 0;
  font-weight: ${theme.font.fontWeights.bold};
`;

export const TextH2 = styled("h2")`
  font-size: ${theme.font.sizes.h2}px;
  margin: 0;
  font-weight: ${theme.font.fontWeights.semiBold};
`;

export const TextH3 = styled("h3")`
  font-size: ${theme.font.sizes.h3}px;
  margin: 0;
  font-weight: ${theme.font.fontWeights.semiBold};
`;

export const TextBody1 = styled("div")`
  font-size: ${theme.font.sizes.body1}px;
  margin: 0;
  font-weight: ${theme.font.fontWeights.regular};
`;

export const TextBody2 = styled("div")`
  font-size: ${theme.font.sizes.body2}px;
  margin: 0;
  font-weight: ${theme.font.fontWeights.regular};
`;
