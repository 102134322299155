import Skeleton from "react-loading-skeleton";
import React from "react";
import { FooterContainer, ItemContainer } from "./styles";

export const ArticleListItemSkeleton = () => {
  return (
    <ItemContainer>
      <Skeleton width={300} height={300} />
      <Skeleton />
      <Skeleton count={2} />
      <FooterContainer>
        <Skeleton width={48} />
        <Skeleton width={48} />
      </FooterContainer>
    </ItemContainer>
  );
};
