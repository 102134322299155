import { useResetPassword } from "./useResetPassword";
import { ButtonContainer, FormContainer, InputContainer } from "./styles";
import { TextStatic } from "../../../components/text";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Button } from "../../../components/button";
import { Input } from "../../../components/input";
import React from "react";

export const ResetPasswordForm = ({
  isValid,
  handleOnSubmit,
  passwordInput,
  confirmPasswordInput,
}: ReturnType<typeof useResetPassword>["formProps"]) => (
  <FormContainer>
    <TextStatic
      translationKey={TranslationKey["main.resetPassword.title"]}
      variant="h1"
    />
    <InputContainer>
      <Input
        placeholder={TranslationKey["main.input.label.password"]}
        {...passwordInput.inputProps}
        type="password"
        isFullWidth
      />
      <TextStatic
        translationKey={
          TranslationKey["main.registerAccount.passwordRequirements"]
        }
        variant="body2"
      />
      <Input
        placeholder={TranslationKey["main.input.label.confirmPassword"]}
        {...confirmPasswordInput.inputProps}
        type="password"
        isFullWidth
      />
    </InputContainer>
    <ButtonContainer>
      <Button
        onClick={handleOnSubmit}
        text={{ translationKey: TranslationKey["main.resetPassword.submit"] }}
        isFullWidth
        isDisabled={!isValid}
      />
    </ButtonContainer>
  </FormContainer>
);
