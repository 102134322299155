import { ListResponseTemplate } from "../utils/ListResponseTemplate";
import { Place, Location } from "../../models";
import { PLACE_TAGS } from "../../enums";
import { QueryParamsCommon, QueryPaginated } from "../../common";

export enum Sort {
  nearby = "location:asc",
  rankingAsc = "ranking:asc",
  nameAsc = "name:asc",
  createdDateAsc = "createdDate:asc",
}

export interface Params extends QueryParamsCommon, QueryPaginated {
  ids?: string[];
  withAdministrativeAreas?: boolean;
  name?: string;
  tags?: PLACE_TAGS[];
  locationPoint?: Pick<Location, "latitude" | "longitude">;
  locationArea?: {
    topLeft: Pick<Location, "latitude" | "longitude">;
    bottomRight: Pick<Location, "latitude" | "longitude">;
  };
  sort?: Sort;
}

export type Response = ListResponseTemplate<Place>;
