import React, { useContext } from "react";
import {
  Container,
  Content,
  FeatureColumn,
  FeatureGroup,
  FeatureRow,
  MainAppImage,
  MainContainer,
  MainImageContainer,
  MainTextContainer,
  NumberGroup,
  NumberList,
  NumberText,
  NumberValue,
  Row,
  Section,
  StoreImg,
} from "./styles";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { TranslationKey, useTranslation } from "@tour-buddy/ui-translations";
import { Button, Variants } from "../../../components/button";
import { Input, useInput, InputProps } from "../../../components/input";

export const emailInputProps: InputProps = {
  type: "email",
  validationSchema: [
    {
      type: "string",
      message: "Invalid email",
      pattern:
        '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    },
  ],
};

const Newsletter = () => {
  const { subscribeNewsletter } = useContext(ApiRequestContext);
  const email = useInput(emailInputProps);

  const handleNewsletterSubscribe = () => {
    if (email.isValid) {
      subscribeNewsletter.request({ variables: { email: email.value } });
    }
  };
  return (
    <Row forceRow>
      <Input
        placeholder={TranslationKey["main.landingPage.newsletter.input"]}
        {...email}
      />
      <Button
        variant={Variants.contained}
        onClick={handleNewsletterSubscribe}
        text={{
          translationKey: TranslationKey["main.landingPage.newsletter.button"],
        }}
      />
    </Row>
  );
};

const StoreImage = ({
  imageUrl,
  link,
  alt,
}: {
  imageUrl: string;
  link: string;
  alt: string;
}) => (
  <a href={link}>
    <StoreImg alt={alt} src={imageUrl} />
  </a>
);

const MainSection = () => {
  const headerText = useTranslation(
    TranslationKey["main.landingPage.main.header"]
  );
  const descriptionText = useTranslation(
    TranslationKey["main.landingPage.main.description"]
  );

  return (
    <Content>
      <MainContainer>
        <MainTextContainer>
          <h1>{headerText}</h1>
          <div>{descriptionText}</div>
          <Row>
            <StoreImage
              alt="google play"
              imageUrl="google-play-badge-en.png"
              link="https://play.google.com/store/apps/details?id=com.wizytor"
            />
            {/*<StoreImage alt="apple store" />*/}
          </Row>
        </MainTextContainer>
        <MainImageContainer>
          <MainAppImage src="app_screenshot.png" />
        </MainImageContainer>
      </MainContainer>
    </Content>
  );
};

const NumbersSection = () => {
  const headerText = useTranslation(
    TranslationKey["main.landingPage.numbers.header"]
  );
  const paragraphText = useTranslation(
    TranslationKey["main.landingPage.numbers.paragraph"]
  );
  const cityTitleText = useTranslation(
    TranslationKey["main.landingPage.numbers.cityTitle"]
  );
  const cityNumberText = useTranslation(
    TranslationKey["main.landingPage.numbers.cityNumber"]
  );
  const countryTitleText = useTranslation(
    TranslationKey["main.landingPage.numbers.countryTitle"]
  );
  const countryNumberText = useTranslation(
    TranslationKey["main.landingPage.numbers.countryNumber"]
  );
  const placesNumberText = useTranslation(
    TranslationKey["main.landingPage.numbers.placesNumber"]
  );
  const placesTitleText = useTranslation(
    TranslationKey["main.landingPage.numbers.placesTitle"]
  );

  return (
    <Content>
      <h2>{headerText}</h2>
      <NumberList>
        <NumberGroup>
          <NumberValue>{cityNumberText}</NumberValue>
          <NumberText>{cityTitleText}</NumberText>
        </NumberGroup>
        <NumberGroup>
          <NumberValue>{countryNumberText}</NumberValue>
          <NumberText>{countryTitleText}</NumberText>
        </NumberGroup>
        <NumberGroup>
          <NumberValue>{placesNumberText}</NumberValue>
          <NumberText>{placesTitleText}</NumberText>
        </NumberGroup>
      </NumberList>
      <h4>{paragraphText}</h4>
    </Content>
  );
};

const FeaturesSection = () => {
  const headerText = useTranslation(
    TranslationKey["main.landingPage.features.header"]
  );
  const feature1HeaderText = useTranslation(
    TranslationKey["main.landingPage.features.feature1Header"]
  );
  const feature1DescriptionText = useTranslation(
    TranslationKey["main.landingPage.features.feature1Description"]
  );
  const feature2HeaderText = useTranslation(
    TranslationKey["main.landingPage.features.feature2Header"]
  );
  const feature2DescriptionText = useTranslation(
    TranslationKey["main.landingPage.features.feature2Description"]
  );
  const feature3HeaderText = useTranslation(
    TranslationKey["main.landingPage.features.feature3Header"]
  );
  const feature3DescriptionText = useTranslation(
    TranslationKey["main.landingPage.features.feature3Description"]
  );
  const feature4HeaderText = useTranslation(
    TranslationKey["main.landingPage.features.feature4Header"]
  );
  const feature4DescriptionText = useTranslation(
    TranslationKey["main.landingPage.features.feature4Description"]
  );

  return (
    <Content>
      <h2>{headerText}</h2>
      <FeatureColumn>
        <FeatureRow>
          <FeatureGroup>
            <h6>{feature1HeaderText}</h6>
            <div>{feature1DescriptionText}</div>
          </FeatureGroup>
          <FeatureGroup>
            <h6>{feature2HeaderText}</h6>
            <div>{feature2DescriptionText}</div>
          </FeatureGroup>
        </FeatureRow>
        <FeatureRow>
          <FeatureGroup>
            <h6>{feature3HeaderText}</h6>
            <div>{feature3DescriptionText}</div>
          </FeatureGroup>
          <FeatureGroup>
            <h6>{feature4HeaderText}</h6>
            <div>{feature4DescriptionText}</div>
          </FeatureGroup>
        </FeatureRow>
      </FeatureColumn>
    </Content>
  );
};

const NewsletterSection = () => {
  const h1Text = useTranslation(
    TranslationKey["main.landingPage.newsletter.header"]
  );
  const h2Text = useTranslation(
    TranslationKey["main.landingPage.newsletter.h2"]
  );
  return (
    <Content>
      <h2>{h1Text}</h2>
      <h4 style={{ fontWeight: 400 }}>{h2Text}</h4>
      <Newsletter />
    </Content>
  );
};

export const LandingPage = () => {
  return (
    <Container>
      <Section>
        <MainSection />
      </Section>
      <Section isColorBackground>
        <NewsletterSection />
      </Section>
      <Section>
        <NumbersSection />
      </Section>
      <Section isColorBackground>
        <FeaturesSection />
      </Section>
      <Section>
        <NewsletterSection />
      </Section>
    </Container>
  );
};
