export const getApproxTime = (
  timeInMinutes: number
): { hours: number; minutes: number } => {
  if (timeInMinutes < 60) {
    return {
      hours: 0,
      minutes: timeInMinutes,
    };
  }
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = Math.floor(timeInMinutes % 60);
  return {
    hours,
    minutes,
  };
};

export const getRelativeTime = (date: Date): string => {
  const RelativeTimeFormat = new Intl.RelativeTimeFormat("en");

  const oldTimestamp = new Date(date).getTime();
  const timestamp = new Date().getTime();
  const seconds = Math.floor(timestamp / 1000);
  const oldSeconds = Math.floor(oldTimestamp / 1000);
  const difference = seconds - oldSeconds;

  let value;
  let unit: "seconds" | "minutes" | "hours" | "days" | "months" | "years";
  if (difference < 60) {
    value = difference;
    unit = "seconds";
  } else if (difference < 3600) {
    value = Math.floor(difference / 60);
    unit = "minutes";
  } else if (difference < 86400) {
    value = Math.floor(difference / 3600);
    unit = "hours";
  } else if (difference < 2620800) {
    value = Math.floor(difference / 86400);
    unit = "days";
  } else if (difference < 31449600) {
    value = Math.floor(difference / 2620800);
    unit = "months";
  } else {
    value = Math.floor(difference / 31449600);
    unit = "years";
  }
  return RelativeTimeFormat.format(value * -1, unit);
};
