interface ThemeColor {
  main: string;
  contrast?: string;
  light?: string;
  dark?: string;
}

interface BorderRadius {
  input: number;
  button: number;
  card: number;
}

interface FontWeights {
  light: number;
  regular: number;
  semiBold: number;
  bold: number;
}

interface FontSize {
  h1: number;
  h2: number;
  h3: number;
  body1: number;
  body2: number;
}

interface MediaQueries {
  desktop: string;
  mobile: string;
}

interface Skeleton {
  baseColor: string;
  highlightColor: string;
}

export enum Colors {
  primary = "primary",
  secondary = "secondary",
  info = "info",
  error = "error",
  success = "success",
}

export interface Theme {
  colors: {
    [Colors.primary]: ThemeColor;
    [Colors.secondary]: ThemeColor;
    [Colors.info]: ThemeColor;
    [Colors.error]: ThemeColor;
    [Colors.success]: ThemeColor;
    gray: ThemeColor;
    text: ThemeColor;
  };
  font: {
    fontFamily: string;
    sizes: FontSize;
    fontWeights: FontWeights;
  };
  borderRadius: BorderRadius;
  mediaQueries: MediaQueries;
  skeleton: Skeleton;
}
