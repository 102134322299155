import { HttpError } from "./HttpError";
import { Common } from "@tour-buddy/interfaces";

export class UnauthorizedError extends HttpError {
  constructor(params?: Partial<Pick<Common.IHttpError, "message" | "error">>) {
    super({
      statusCode: 401,
      type: "UNAUTHORIZED_ERROR",
      message: "User is not authenticated",
      error: params,
    });
  }
}
