import { HttpError } from "./HttpError";
import { Common } from "@tour-buddy/interfaces";

export class InternalError extends HttpError {
  constructor(params: Partial<Pick<Common.IHttpError, "message" | "error">>) {
    super({
      statusCode: 500,
      type: "INTERNAL_SERVER_ERROR",
      message: "Internal error occurred",
      error: params,
    });
  }
}
