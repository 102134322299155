import React from "react";

export const RemoveAccount = () => {
  return (
    <div>
      <strong>Remove account</strong>
      <p>If you want to remove account, message us</p>
      <a href="mailto:wizytor.app@gmail.com">Contact us</a>
    </div>
  );
};
