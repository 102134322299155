import "react-loading-skeleton/dist/skeleton.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  from,
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import { config } from "./config";
import { authMiddleware, authTokenMiddleware } from "./components/authProvider";

const httpLink = new HttpLink({ uri: config.apiUrl });

const gqlClient = new ApolloClient({
  link: from([
    // @ts-ignore
    authTokenMiddleware,
    authMiddleware,
    httpLink,
  ]),
  uri: config.apiUrl,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ApolloProvider client={gqlClient}>
    <App />
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
