import styled from "@emotion/styled";
import { theme } from "../../../../theme";
import { PinIcon, WalkingIcon } from "../../../../components/icons";
import { Colors } from "../../../../types";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;

  & > * {
    padding: 8px;
  }

  ${theme.mediaQueries.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const DetailContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;

  &:first-child {
    flex: 1;
  }

  &:last-child {
    flex: 1;
    justify-content: flex-end;
  }
`;

export const AddressIcon = styled(PinIcon)`
  width: 24px;
  height: 24px;
  stroke: ${theme.colors[Colors.primary].main};
`;

export const TimeIcon = styled(WalkingIcon)`
  width: 24px;
  height: 24px;
  stroke: ${theme.colors[Colors.primary].main};
`;
