import { useInput } from "../../../components/input";
import { INPUT_EMAIL_VALIDATION } from "./constants";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { pageUrls } from "../../urls";
import { PageState } from "./types";

const getPageState = (
  isSuccess: boolean,
  isRequestFinished: boolean
): PageState => {
  if (isRequestFinished) {
    return isSuccess ? PageState.success : PageState.error;
  }
  return PageState.form;
};

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useContext(ApiRequestContext);
  const emailInput = useInput({ validationSchema: INPUT_EMAIL_VALIDATION });

  const isValid = emailInput.isValid;
  const isRequestFinished = forgotPassword.isFinished;
  const isSuccess = forgotPassword.data?.statusCode === 200;
  const pageState = getPageState(isSuccess, isRequestFinished);

  const handleOnSubmit = () => {
    if (isValid) {
      forgotPassword.request({
        variables: {
          email: emailInput.value,
        },
      });
    }
  };

  const handleOnLoginRedirect = () => {
    navigate(pageUrls.login());
  };

  const handleOnBack = () => {
    navigate(pageUrls.homepage());
  };

  const formProps = {
    handleOnSubmit,
    handleOnLoginRedirect,
    emailInput,
    isValid,
  };

  const successProps = {
    handleOnBack,
  };

  const errorProps = {
    handleOnBack,
  };

  return {
    formProps,
    successProps,
    errorProps,
    pageState,
  };
};
