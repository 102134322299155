import { useInput } from "../../../components/input";
import { useNavigate } from "react-router-dom";
import { pageUrls } from "../../urls";
import { useContext, useEffect } from "react";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { INPUT_PASSWORD_VALIDATION, INPUT_EMAIL_VALIDATION } from "./constants";

export const useLogin = () => {
  const navigate = useNavigate();
  const { loginUser } = useContext(ApiRequestContext);

  const loginInput = useInput({ validationSchema: INPUT_EMAIL_VALIDATION });
  const passwordInput = useInput({
    validationSchema: INPUT_PASSWORD_VALIDATION,
  });

  const isValid = loginInput.isValid && passwordInput.isValid;

  useEffect(() => {
    if (loginUser.isFinished && loginUser.data?.statusCode === 200) {
      navigate(pageUrls.homepage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.isFinished]);

  const handleOnSubmit = () => {
    if (isValid) {
      loginUser.request({
        variables: {
          email: loginInput.value,
          password: passwordInput.value,
        },
      });
    }
  };

  const handleOnRegisterRedirect = () => {
    navigate(pageUrls.createAccount());
  };

  const handleOnForgotPassword = () => {
    navigate(pageUrls.forgotPassword());
  };

  return {
    handleOnSubmit,
    handleOnRegisterRedirect,
    handleOnForgotPassword,
    loginInput,
    passwordInput,
    isValid,
  };
};
