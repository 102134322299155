import React from "react";
import { ImageProps } from "./types";
import { ImageContainer, ImageStyled } from "./styles";
import Skeleton from "react-loading-skeleton";

export const Image = ({ imageUrl, imageAlt }: ImageProps) => {
  return imageUrl ? (
    <ImageContainer>
      <ImageStyled src={imageUrl} alt={imageAlt} />
    </ImageContainer>
  ) : null;
};

export const ImageSkeleton = () => {
  return (
    <ImageContainer>
      <Skeleton height={600} width="100%" />
    </ImageContainer>
  );
};
