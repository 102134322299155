import { useContext, useEffect } from "react";
import { ApiDataContext, ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { ArticleProps } from "../../../components/articleList/types";

export const useArticleList = () => {
  const { articles } = useContext(ApiDataContext);
  const { getArticles } = useContext(ApiRequestContext);

  useEffect(() => {
    getArticles.request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !getArticles.isFinished;

  const articlesList: ArticleProps[] = articles.map((article) => ({
    title: article.title || "",
    imageUrl: article.thumbnailImageUrl || "",
    path: article?.paths?.[0]?.value || "",
    language: article?.paths?.[0]?.language?.toLowerCase() || "",
    updatedAt: article.updatedAt ? new Date(article.updatedAt) : new Date(),
    description: article?.description || "",
    estimatedTime: article?.estimateReadingTime || 2,
  }));

  return { articles: articlesList, isLoading };
};
