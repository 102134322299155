import { useContext, useEffect } from "react";
import { ApiDataContext, ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { Models } from "@tour-buddy/interfaces";
import { PlanListItemProps } from "../../../components/plansList";

export const usePlansList = () => {
  const { userPlans } = useContext(ApiDataContext);
  const { getUserPlans } = useContext(ApiRequestContext);

  useEffect(() => {
    getUserPlans.request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userPlansList: PlanListItemProps[] = userPlans.map((userPlan) => {
    const {
      startAddress = [],
      availableTime = [],
      places = [],
      placeIds = [],
      availableDays = 1,
      name = "",
    } = userPlan || {};

    const imageUrls = (places?.flat() as Models.Place[])
      ?.slice(0, 4)
      ?.map(
        ({ images }) => images?.[0]?.thumbnailLink || images?.[0]?.link || ""
      );

    return {
      id: userPlan.id || "",
      name,
      startAddress,
      imageUrls,
      availableTime,
      availableDays,
      placeIds: placeIds.flat(),
    };
  });

  const isLoading = !getUserPlans.isFinished;

  return { userPlansList, isLoading };
};
