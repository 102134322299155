import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled("div")`
  width: calc(100% - 64px);
  max-width: 1000px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 32px 0;
  }
`;
