import React from "react";
import { PlanProps } from "../types";
import { Models } from "@tour-buddy/interfaces";
import {
  PlansList,
  PlanListItemProps,
} from "../../../../../components/plansList";

export const Plan = ({ plan }: PlanProps) => {
  const startAddress = plan?.startAddress || [];
  const imageUrls = (plan?.places?.flat() as Models.Place[])
    ?.slice(0, 4)
    ?.map(
      ({ images }) => images?.[0]?.thumbnailLink || images?.[0]?.link || ""
    );
  const availableDays = plan?.availableDays || 0;
  const availableTime = plan?.availableTime || [];
  const id = plan?.id || "";
  const name = plan?.name || "";
  const placeIds = plan?.placeIds || [];

  const userPlan: PlanListItemProps = {
    name,
    placeIds: placeIds.flat(),
    id,
    availableDays,
    availableTime,
    imageUrls,
    startAddress,
  };

  return <PlansList userPlans={[userPlan]} />;
};
