import React from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../../components/text";
import { pageUrls } from "../../../urls";
import { Button, Size } from "../../../../components/button";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Place } from "./types";
import { BottomContainer, Image, ItemContainer } from "./styles";

export const PlaceListItem = ({ imageUrl, id, name }: Place) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(pageUrls.placeDetails(id));
  };

  return (
    <ItemContainer onClick={handleOnClick}>
      <Image src={imageUrl} alt={id} />
      <BottomContainer>
        <Text text={name} variant="h2" />
        <Button
          onClick={handleOnClick}
          text={{
            translationKey: TranslationKey["main.plan.placeDetailsButton"],
          }}
          size={Size.small}
        />
      </BottomContainer>
    </ItemContainer>
  );
};
