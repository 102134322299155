import React from "react";
import { TiqetsDiscoveryWidgetProps } from "./types";

const PARTNER_ID = "wizytor-170309";

export const TIQETS_WIDGET_SCRIPT_LOADER = <script src="https://widgets.tiqets.com/loader.js"></script>;

export const TiqetsDiscoveryWidget = ({
  destinationId,
  destinationType,
  itemCount = 3,
  campaignId = "",
}: TiqetsDiscoveryWidgetProps) => (
  <>
    <div
      data-tiqets-widget="discovery"
      data-cards-layout="responsive"
      data-content-type="product"
      data-item_count={`${itemCount}`}
      data-destination-type={destinationType}
      data-destination-id={destinationId}
      data-partner={PARTNER_ID}
      data-tq-campaign={campaignId}
    />
  </>
);
