import React from "react";
import {
  Row,
  RightContainer,
  ImagesContainer,
  ItemContainer,
  DetailsContainer,
} from "./styles";
import Skeleton from "react-loading-skeleton";

export const PlanListItemSkeleton = () => {
  return (
    <ItemContainer>
      <ImagesContainer>
        <Skeleton
          width={200}
          height={200}
          style={{
            lineHeight: "normal",
            borderTopLeftRadius: 18,
            borderBottomLeftRadius: 18,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
      </ImagesContainer>
      <RightContainer>
        <DetailsContainer>
          <Skeleton width={200} />
          <Skeleton width={280} />
          <Row>
            <Skeleton count={1} width={100} />
            <Skeleton count={1} width={100} />
          </Row>
        </DetailsContainer>
        <Skeleton width={200} height={48} />
      </RightContainer>
    </ItemContainer>
  );
};
