import React from "react";
import { Container, Content, DetailsContainer } from "./styles";
import { ActionsSkeleton } from "./actions/actions.skeleton";
import Skeleton from "react-loading-skeleton";
import { AdministrativeAreasSkeleton } from "./administrativeAreas/administrativeAreas.skeleton";
import { FactsSummarySkeleton } from "./factsSummary/factsSummary.skeleton";
import { ImageGallerySkeleton } from "./imageGallery/imageGallery.skeleton";

export const PlaceDetailsSkeleton = () => {
  return (
    <Container>
      <Content>
        <DetailsContainer>
          <Skeleton width={300} height={48} />
          <ImageGallerySkeleton />
          <ActionsSkeleton />
          <FactsSummarySkeleton />
          <AdministrativeAreasSkeleton />
        </DetailsContainer>
      </Content>
    </Container>
  );
};
