import styled from "@emotion/styled";
import { theme } from "../../../theme";

export const Container = styled("div")<{ isOpen?: boolean }>`
  background-color: white;
  height: 100%;
  width: 100%;
  position: fixed;
  top: ${({ isOpen }) => (isOpen ? 0 : "-100%")};
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  transition: right 300ms ease-in-out, top 300ms ease-in-out;

  ${theme.mediaQueries.desktop} {
    width: 300px;
    top: 0;
    right: ${({ isOpen }) => (isOpen ? 0 : "-300px")};
    box-shadow: 4px 0px 12px 0px rgba(66, 68, 90, 0.5);
  }
`;

export const MobileOnlyContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${theme.mediaQueries.desktop} {
    display: none;
  }
`;

export const DesktopContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  ${theme.mediaQueries.desktop} {
    justify-content: space-between;
  }
`;

export const TopContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BottomContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
