import React from "react";
import { PlansListContainer } from "./styles";
import { PlanListItem } from "./planListItem";
import { PlansListProps } from "./types";
import { PlanListItemSkeleton } from "./planListItem/planListItem.skeleton";

export const PlansList = ({
  userPlans = [],
  isLoading = false,
  loadingItemsCount = 1,
}: PlansListProps) => {
  return (
    <PlansListContainer>
      {isLoading
        ? Array(loadingItemsCount)
            .fill(0)
            .map(() => <PlanListItemSkeleton />)
        : userPlans.map((userPlan) => (
            <PlanListItem {...userPlan} key={userPlan.id} />
          ))}
    </PlansListContainer>
  );
};
