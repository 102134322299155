import { useInput } from "../../../components/input";
import {
  INPUT_PASSWORD_VALIDATION,
  INPUT_EMAIL_VALIDATION,
  INPUT_LAST_NAME_VALIDATION,
  INPUT_FIRST_NAME_VALIDATION,
} from "./constants";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { pageUrls } from "../../urls";
import { PageState } from "./types";

const getPageState = (
  isSuccess: boolean,
  isRequestFinished: boolean
): PageState => {
  if (isRequestFinished) {
    return isSuccess ? PageState.success : PageState.error;
  }
  return PageState.form;
};

export const useCreateAccount = () => {
  const navigate = useNavigate();
  const { createUser } = useContext(ApiRequestContext);

  const emailInput = useInput({ validationSchema: INPUT_EMAIL_VALIDATION });
  const passwordInput = useInput({
    validationSchema: INPUT_PASSWORD_VALIDATION,
  });
  const confirmPasswordInput = useInput({
    validationSchema: INPUT_PASSWORD_VALIDATION,
  });
  const firstNameInput = useInput({
    validationSchema: INPUT_FIRST_NAME_VALIDATION,
  });
  const lastNameInput = useInput({
    validationSchema: INPUT_LAST_NAME_VALIDATION,
  });

  const isValid =
    emailInput.isValid &&
    passwordInput.isValid &&
    confirmPasswordInput.isValid &&
    firstNameInput.isValid &&
    lastNameInput.isValid &&
    passwordInput.value === confirmPasswordInput.value;
  const isRequestFinished = createUser.isFinished;
  const isSuccess = createUser.data?.statusCode === 200;
  const pageState = getPageState(isSuccess, isRequestFinished);

  const handleOnSubmit = () => {
    if (isValid) {
      createUser.request({
        variables: {
          email: emailInput.value,
          password: passwordInput.value,
          firstName: firstNameInput.value,
          lastName: lastNameInput.value,
        },
      });
    }
  };

  const handleOnLoginRedirect = () => {
    createUser.cleanData();
    navigate(pageUrls.login());
  };

  const handleOnBack = () => {
    createUser.cleanData();
    navigate(pageUrls.homepage());
  };

  const formProps = {
    handleOnSubmit,
    handleOnLoginRedirect,
    emailInput,
    passwordInput,
    confirmPasswordInput,
    firstNameInput,
    lastNameInput,
    isValid,
  };

  const successProps = {
    handleOnLoginRedirect,
    handleOnBack,
  };

  const errorProps = {
    handleOnBack,
  };

  return {
    formProps,
    successProps,
    errorProps,
    pageState,
  };
};
