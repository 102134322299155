import {
  ActionProps,
  ActionHandlerProps,
  UserAuthorizationType,
} from "./types";
import { pageUrls } from "../../pages/urls";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { Variants } from "../button";

export const HEADER_ACTIONS = ({
  navigate,
}: ActionHandlerProps): ActionProps[] => [
  {
    type: "button",
    translationKey: TranslationKey["main.drawer.button.blog"],
    onClick: () => navigate(pageUrls.homepage()),
  },
  {
    type: "button",
    translationKey: TranslationKey["main.button.login"],
    onClick: () => navigate(pageUrls.login()),
    userAuthorizationType: UserAuthorizationType.notAuthorized,
  },
  {
    type: "button",
    translationKey: TranslationKey["main.login.registerRedirectButton"],
    onClick: () => navigate(pageUrls.createAccount()),
    buttonVariant: Variants.outlined,
    userAuthorizationType: UserAuthorizationType.notAuthorized,
  },
];

export const TOP_ACTIONS = ({
  navigate,
}: ActionHandlerProps): ActionProps[] => [];

export const BOTTOM_ACTIONS = ({
  navigate,
  onLogout,
}: ActionHandlerProps): ActionProps[] => [
  {
    type: "button",
    translationKey: TranslationKey["main.button.logout"],
    onClick: () => {
      onLogout();
    },
    userAuthorizationType: UserAuthorizationType.authorized,
  },
];
