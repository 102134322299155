import React from "react";
import { ActionsProps } from "./types";
import { Container } from "./styles";
import { Button, Variants } from "../../../../components/button";

export const Actions = ({ wikipediaUrl }: ActionsProps) => {
  const handleWikipediaClick = () => {
    window.location.replace(wikipediaUrl);
  };

  return (
    <Container>
      <Button
        onClick={handleWikipediaClick}
        variant={Variants.outlined}
        text={{ text: "Wikipedia" }}
      />
    </Container>
  );
};
