import styled from "@emotion/styled";
import { theme } from "../../theme";

export const HeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  & > h2 {
    font-size: ${theme.font.sizes.h1}px;
  }
`;

export const ListContainer = styled("div")`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 32px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, 300px);
  width: 100%;

  @media (max-width: 768px) {
    grid-gap: 8px;
  }
`;

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Image = styled("img")`
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
`;

export const ItemContainer = styled("div")`
  max-width: 300px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Description = styled("div")`
  color: ${theme.colors.text.main};
  font-size: ${theme.font.sizes.body1}px;
  font-weight: ${theme.font.fontWeights.regular};
  line-height: 1.1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const FooterText = styled("div")`
  font-size: ${theme.font.sizes.body1}px;
  font-weight: ${theme.font.fontWeights.regular};
  color: ${theme.colors.gray.main};
`;

export const FooterContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled("div")`
  color: ${theme.colors.text.main};
  font-size: ${theme.font.sizes.h3}px;
  font-weight: ${theme.font.fontWeights.semiBold};
  line-height: 1.1;
`;
