import { useContext, useEffect } from "react";
import { ApiDataContext, ApiRequestContext } from "@tour-buddy/ui-api-provider";
import {
  ArticleListProps,
  ArticleProps,
} from "../../../components/articleList/types";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { useNavigate } from "react-router-dom";
import { pageUrls } from "../../urls";

export const useBlogHomepage = () => {
  const { articles } = useContext(ApiDataContext);
  const { getArticles } = useContext(ApiRequestContext);
  const navigate = useNavigate();

  useEffect(() => {
    getArticles.request({
      variables: {
        limit: 3,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnTopPlaceShowAllClick = () => {
    navigate(pageUrls.articles());
  };

  const articlesList: ArticleProps[] = articles.map((article) => ({
    title: article.title || "",
    imageUrl: article.thumbnailImageUrl || "",
    path: article?.paths?.[0]?.value || "",
    language: article?.paths?.[0]?.language?.toLowerCase() || "",
    updatedAt: article.updatedAt ? new Date(article.updatedAt) : new Date(),
    description: article?.description || "",
    estimatedTime: article?.estimateReadingTime || 2,
  }));

  const topArticles: ArticleListProps = {
    articles: articlesList,
    header: {
      text: TranslationKey["main.main.topArticles.header"],
      actionText: TranslationKey["main.main.topArticles.showAll"],
      actionClick: handleOnTopPlaceShowAllClick,
    },
    isLoading: !getArticles.isFinished,
    loadingItemsCount: 3,
  };

  return { topArticles };
};
