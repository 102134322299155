import React from "react";
import { Container, Content } from "./styles";
import { ContentSuccess } from "./contentSuccess";
import { ContentError } from "./contentError";
import { ContentValidation } from "./contentValidation";
import { useVerifyAccount } from "./useVerifyAccount";
import { PageState } from "./types";

export const VerifyAccount = () => {
  const { validationProps, errorProps, successProps, pageState } =
    useVerifyAccount();

  return (
    <Container>
      <Content>
        {pageState === PageState.success && (
          <ContentSuccess {...successProps} />
        )}
        {pageState === PageState.error && <ContentError {...errorProps} />}
        {pageState === PageState.validation && (
          <ContentValidation {...validationProps} />
        )}
      </Content>
    </Container>
  );
};
