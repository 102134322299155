import React from "react";
import { Container } from "./styles";
import Skeleton from "react-loading-skeleton";

export const FactsSummarySkeleton = () => {
  return (
    <Container>
      <Skeleton count={5} />
      <Skeleton width={100} height={32} />
    </Container>
  );
};
