import React from "react";
import { ButtonProps, Colors, Size, Variants } from "./types";
import { ContainerOutlined, ContainerText, ContainerContained } from "./styles";
import { TextStaticOptional, Variants as TextVariants } from "../text";

const containerMap: Record<Variants, React.FC<any>> = {
  [Variants.contained]: ContainerContained,
  [Variants.outlined]: ContainerOutlined,
  [Variants.text]: ContainerText,
};

const textSizeMap: Record<Size, TextVariants> = {
  [Size.tiny]: "body2",
  [Size.small]: "body1",
  [Size.medium]: "h3",
  [Size.large]: "h2",
};

const paddingVerticalMap: Record<Size, string> = {
  [Size.tiny]: "2px",
  [Size.small]: "4px",
  [Size.medium]: "12px",
  [Size.large]: "16px",
};

const paddingHorizontalMap: Record<Size, string> = {
  [Size.tiny]: "4px",
  [Size.small]: "8px",
  [Size.medium]: "16px",
  [Size.large]: "32px",
};

export const Button: React.FC<ButtonProps> = ({
  text,
  align = "center",
  variant = Variants.contained,
  color = Colors.primary,
  size = Size.medium,
  onClick,
  Icon,
  isFullWidth = false,
  isDisabled = false,
}) => {
  const Container = containerMap[variant] || ContainerContained;
  const textSizeVariant = textSizeMap[size] || textSizeMap[Size.medium];
  const paddingVertical =
    paddingVerticalMap[size] || paddingVerticalMap[Size.medium];
  const paddingHorizontal =
    paddingHorizontalMap[size] || paddingHorizontalMap[Size.medium];

  return (
    <Container
      disabled={isDisabled}
      color={color}
      paddingVertical={paddingVertical}
      paddingHorizontal={paddingHorizontal}
      onClick={onClick}
      fullWidth={isFullWidth}
      align={align}
    >
      {Icon ? Icon : null}
      {text && <TextStaticOptional variant={textSizeVariant} {...text} />}
    </Container>
  );
};
