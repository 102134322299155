import { useContext, useEffect, useState } from "react";
import { ApiDataContext, ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { Models } from "@tour-buddy/interfaces";
import { useAuth } from "../../../components/authProvider";
import { useParams } from "react-router-dom";
import { getApproxTime } from "../../../utils/time";
import { DayDetails, PlaceDetails } from "./types";

const mapPlaceDetails = ({
  id,
  name,
  images = [],
}: Models.Place): PlaceDetails => ({
  id,
  name,
  imageUrl: images[0]?.thumbnailLink || images[0]?.link || "",
});

const mapDayDetails = (props: {
  dayNumber: number;
  places: Models.Place[];
  startAddress: Models.Location;
  finishAddress: Models.Location;
  availableTime: number;
}): DayDetails => {
  const { dayNumber, finishAddress, startAddress, places, availableTime } =
    props;
  const relativeTime = availableTime ? getApproxTime(availableTime) : null;
  const { minutes = 0, hours = 0 } = relativeTime || {};

  return {
    dayNumber,
    finishAddress: finishAddress?.displayAddress || "",
    startAddress: startAddress?.displayAddress || "",
    availableTime: {
      hours,
      minutes,
    },
    places: places.map(mapPlaceDetails),
  };
};

const DEFAULT_DAY = 1;

export const usePlanDetails = () => {
  const [selectedDay, setSelectedDay] = useState(DEFAULT_DAY);
  const { userData } = useAuth();
  const { selectedUserPlan } = useContext(ApiDataContext);
  const { getSelectedUserPlan } = useContext(ApiRequestContext);
  const { planId } = useParams();

  useEffect(() => {
    getSelectedUserPlan.request({
      variables: { userPlanId: planId },
      fetchPolicy: "no-cache",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !getSelectedUserPlan.isFinished;

  const name = selectedUserPlan.name || "";
  const administrativeAreaPlace = selectedUserPlan?.administrativeAreaPlace
    ? mapPlaceDetails(selectedUserPlan.administrativeAreaPlace)
    : null;
  const isAddressSpecific = selectedUserPlan.isAddressSpecific;
  const isTimeSpecific = selectedUserPlan.isTimeSpecific;

  const places = selectedUserPlan.places || [];
  const startAddress = selectedUserPlan.startAddress || [];
  const finishAddress = selectedUserPlan.finishAddress || [];
  const availableTime = selectedUserPlan.availableTime || [];
  const isUserOwner = userData
    ? selectedUserPlan.userId === userData?.id
    : false;
  const numberOfDays = selectedUserPlan.availableDays || DEFAULT_DAY;

  const days = [...Array(numberOfDays)].map((_, dayIndex) => {
    const dayNumber = dayIndex + 1;
    return mapDayDetails({
      dayNumber,
      places: places[dayIndex] || [],
      startAddress: startAddress[dayIndex] || {},
      finishAddress: isAddressSpecific
        ? selectedUserPlan.isSameStartEnd
          ? startAddress[dayIndex]
          : finishAddress[dayIndex]
        : selectedUserPlan.isSameStartEnd
        ? startAddress[0]
        : finishAddress[0],
      availableTime: isTimeSpecific
        ? availableTime[dayIndex]
        : availableTime[0],
    });
  });
  const handleOnPlanAdd = () => {};

  const handleOnNextDay = () => {
    if (selectedDay < numberOfDays) {
      setSelectedDay((prev) => prev + 1);
    }
  };

  const handleOnPreviousDay = () => {
    if (selectedDay > 1) {
      setSelectedDay((prev) => prev - 1);
    }
  };

  const handleOnDayClick = (newDay: number) => {
    setSelectedDay(newDay);
  };

  return {
    name,
    isUserOwner,
    administrativeAreaPlace,
    days,
    selectedDay,
    handleOnPlanAdd,
    handleOnNextDay,
    handleOnPreviousDay,
    handleOnDayClick,
    isLoading,
  };
};
