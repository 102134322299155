import React from "react";
import { Container, DetailsContainer, ItemContainer } from "./styles";
import Skeleton from "react-loading-skeleton";

const AdministrativeAreaItem = () => {
  return (
    <ItemContainer isGray>
      <Skeleton
        width={80}
        height={80}
        style={{
          lineHeight: "normal",
          borderTopLeftRadius: 18,
          borderBottomLeftRadius: 18,
        }}
      />
      <DetailsContainer>
        <Skeleton width={120} height={24} />
        <Skeleton width={100} height={24} />
      </DetailsContainer>
    </ItemContainer>
  );
};

export const AdministrativeAreasSkeleton = () => {
  return (
    <Container>
      <AdministrativeAreaItem />
      <AdministrativeAreaItem />
      <AdministrativeAreaItem />
      <AdministrativeAreaItem />
      <AdministrativeAreaItem />
      <AdministrativeAreaItem />
    </Container>
  );
};
