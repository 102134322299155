import React, { useContext, useEffect, useState } from "react";
import {
  getLocalStorageItem,
  LOCAL_STORAGE_KEYS,
  setLocalStorageItem,
  removeLocalStorageItem,
} from "../../utils/localStorage";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { isJsonString } from "../../utils/json";
import { HttpError } from "@tour-buddy/core-errors";

interface AuthContextValue {
  isAuthorized: boolean;
  authToken: string | null;
  refreshToken: string | null;
  userData: Record<string, any> | null;
}

const initialState = {
  authToken: null,
  refreshToken: null,
  userData: null,
  isAuthorized: false,
};

export const AuthContext = React.createContext<AuthContextValue>(initialState);

const getLocalStorageData = () => {
  let authToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  let refreshToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  let userData = getLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA);

  return {
    authToken,
    refreshToken,
    userData: userData && isJsonString(userData) ? JSON.parse(userData) : null,
  };
};

export const AuthProvider: React.FC<{ children: any }> = ({ children }) => {
  const { authUser, loginUser } = useContext(ApiRequestContext);

  const [authToken, setAuthToken] = useState<AuthContextValue["authToken"]>(
    initialState.authToken
  );
  const [refreshToken, setRefreshToken] = useState<
    AuthContextValue["refreshToken"]
  >(initialState.refreshToken);
  const [userData, setUserData] = useState<AuthContextValue["userData"]>(
    initialState.userData
  );
  const [isAuthorized, setIsAuthorized] = useState<
    AuthContextValue["isAuthorized"]
  >(initialState.isAuthorized);
  const localStorageData = getLocalStorageData();

  useEffect(() => {
    if (localStorageData.userData !== userData)
      setUserData(localStorageData.userData);
    if (localStorageData.authToken !== authToken)
      setAuthToken(localStorageData.authToken);
    if (localStorageData.refreshToken !== refreshToken)
      setRefreshToken(localStorageData.refreshToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authToken) {
      authUser.request();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    if (authUser.isFinished || loginUser.isFinished) {
      const unauthorizedError = [
        ...((authUser?.response?.error?.graphQLErrors ||
          []) as any as HttpError[]),
        ...((loginUser?.response?.error?.graphQLErrors ||
          []) as any as HttpError[]),
      ]?.find(({ type }) => type === "UNAUTHORIZED_ERROR");
      if (unauthorizedError) {
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.USER_DATA);
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

        setIsAuthorized(false);
      } else {
        const userDataResponse =
          authUser?.data?.data?.user || loginUser?.data?.data?.user || {};
        const authTokenResponse =
          authUser?.data?.data?.auth?.authToken ||
          loginUser?.data?.data?.auth?.authToken;
        const refreshTokenResponse =
          authUser?.data?.data?.auth?.refreshToken ||
          loginUser?.data?.data?.auth?.refreshToken;

        console.log({
          userDataResponse,
          authTokenResponse,
          refreshTokenResponse,
          loginUser,
        });

        if (authTokenResponse) {
          setLocalStorageItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, authTokenResponse);
          setAuthToken(authTokenResponse);
        }
        if (refreshTokenResponse) {
          setLocalStorageItem(
            LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
            refreshTokenResponse
          );
          setRefreshToken(refreshTokenResponse);
        }
        if (authUser?.data?.user) {
          setLocalStorageItem(
            LOCAL_STORAGE_KEYS.USER_DATA,
            JSON.stringify(userDataResponse)
          );
          setUserData(userDataResponse);
        }

        setIsAuthorized(!!authTokenResponse);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.isFinished, loginUser.isFinished]);

  return (
    <AuthContext.Provider
      value={{ authToken, refreshToken, userData, isAuthorized }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
