import React from "react";
import { Button, Colors, Variants } from "../../../../components/button";
import { TranslationKey } from "@tour-buddy/ui-translations";
import { DaySelectorProps } from "./types";
import { Container } from "./styles";

export const DaySelector = ({
  selectedDay,
  maxDays,
  handleOnPreviousDay,
  handleOnNextDay,
  handleOnDayClick,
}: DaySelectorProps) => {
  return maxDays > 1 ? (
    <Container>
      <Button
        isDisabled={selectedDay === 1}
        variant={Variants.text}
        onClick={() => handleOnPreviousDay()}
        text={{ text: "<" }}
      />
      {[...Array(maxDays)].map((_, index) => {
        const dayNumber = index + 1;

        return (
          <Button
            color={selectedDay === index + 1 ? Colors.primary : Colors.text}
            variant={Variants.text}
            onClick={() => handleOnDayClick(index + 1)}
            text={{
              translationKey: TranslationKey["main.plan.placesListHeader"],
              translationInputMap: { day: `${dayNumber}` },
            }}
            key={index}
          />
        );
      })}
      <Button
        isDisabled={selectedDay === maxDays}
        variant={Variants.text}
        onClick={() => handleOnNextDay()}
        text={{ text: ">" }}
      />
    </Container>
  ) : null;
};
