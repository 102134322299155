import { ValidationCheckFunction } from "../types";

export const isMaxLengthInvalid: ValidationCheckFunction<
  number | undefined,
  string
> = (maxLength, data): boolean =>
  typeof maxLength !== "undefined" && data.length < maxLength;

export const isMinLengthInvalid: ValidationCheckFunction<
  number | undefined,
  string
> = (minLength, data): boolean =>
  typeof minLength !== "undefined" && data.length < minLength;

export const isRequiredInvalid: ValidationCheckFunction<
  boolean | undefined,
  string
> = (required, data): boolean =>
  typeof required !== "undefined" && required && !data;

export const isPatternInvalid: ValidationCheckFunction<
  string | undefined,
  string
> = (pattern, data): boolean =>
  typeof pattern !== "undefined" && !new RegExp(pattern).test(data);
