import styled from "@emotion/styled";
import { AlertCircleIcon, CheckCircleIcon } from "../../../components/icons";
import { theme } from "../../../theme";
import { Colors } from "../../../types";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px - 56px);
`;

export const Content = styled("div")`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & > * {
    text-align: center;
  }
`;

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 64px;
  height: 64px;
  stroke: ${theme.colors[Colors.success].main};
`;

export const StyledAlertCircleIcon = styled(AlertCircleIcon)`
  width: 48px;
  height: 48px;
  stroke: ${theme.colors[Colors.error].main};
`;
