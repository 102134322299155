import React, { useState } from "react";
import { FactsSummaryProps } from "./types";
import { Container } from "./styles";
import { Text } from "../../../../components/text";
import { Button, Colors, Variants } from "../../../../components/button";
import { TranslationKey } from "@tour-buddy/ui-translations";

export const FactsSummary = ({ text }: FactsSummaryProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleOnShowMoreLessClick = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  return (
    <Container numberOfLines={isCollapsed ? 5 : undefined}>
      <Text text={text} />
      <Button
        variant={Variants.text}
        color={Colors.primary}
        text={{
          translationKey: isCollapsed
            ? TranslationKey["main.button.readMore"]
            : TranslationKey["main.button.readLess"],
        }}
        onClick={handleOnShowMoreLessClick}
      />
    </Container>
  );
};
