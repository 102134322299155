import React from "react";
import { TranslationKey } from "@tour-buddy/ui-translations";
import {
  Container,
  Content,
  DetailsContainer,
  PlacesContainer,
} from "./styles";
import { Text } from "../../../components/text";
import { PlaceListItem } from "./placeListItem";
import { usePlanDetails } from "./usePlanDetails";
import { Button } from "../../../components/button";
import { DaySelector } from "./daySelector";
import { DayDetails } from "./dayDetails";
import { PlanDetailsSkeleton } from "./planDetails.skeleton";

export const PlanDetails = () => {
  const {
    name,
    isUserOwner,
    administrativeAreaPlace,
    days,
    selectedDay,
    handleOnPlanAdd,
    handleOnNextDay,
    handleOnPreviousDay,
    handleOnDayClick,
    isLoading,
  } = usePlanDetails();

  return isLoading ? (
    <PlanDetailsSkeleton />
  ) : (
    <Container>
      <Content>
        <DetailsContainer>
          <Text text={name} variant="h1" />
          {!isUserOwner && (
            <Button
              onClick={handleOnPlanAdd}
              text={{ translationKey: TranslationKey["main.plan.addPlan"] }}
            />
          )}
          {administrativeAreaPlace && (
            <PlaceListItem {...administrativeAreaPlace} />
          )}
          <DaySelector
            selectedDay={selectedDay}
            maxDays={days.length}
            handleOnPreviousDay={handleOnPreviousDay}
            handleOnNextDay={handleOnNextDay}
            handleOnDayClick={handleOnDayClick}
          />
          <DayDetails {...days[selectedDay - 1]} />
          <PlacesContainer>
            {days[selectedDay - 1]?.places.map((place) => (
              <PlaceListItem {...place} />
            ))}
          </PlacesContainer>
        </DetailsContainer>
      </Content>
    </Container>
  );
};
