import { Common } from "@tour-buddy/interfaces";
import { Config } from "./types";
import configDev from "./development";
import configProd from "./production";

const Environment = Common.Environment;

const CONFIGS = {
  [Environment.dev]: configDev,
  [Environment.prod]: configProd,
};

const env = process.env.NODE_ENV || Environment.dev;
// @ts-ignore
export const config: Config = CONFIGS[env];
