import React, { useContext, useState } from "react";

interface IDrawerContext {
  isOpen: boolean;
  toggleIsOpen: (value?: boolean) => void;
}

const defaultValue: IDrawerContext = {
  isOpen: false,
  toggleIsOpen: () => {},
};

export const DrawerContext = React.createContext<IDrawerContext>(defaultValue);

export const DrawerProvider: React.FC<{ children: any }> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleIsOpen = (value?: boolean) => {
    if (value !== undefined) {
      setIsDrawerOpen(value);
    } else {
      setIsDrawerOpen((prevValue) => !prevValue);
    }
  };

  return (
    <DrawerContext.Provider value={{ isOpen: isDrawerOpen, toggleIsOpen }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = (): IDrawerContext => useContext(DrawerContext);
