import styled from "@emotion/styled";

export const Container = styled("div")<{ numberOfLines?: number }>`
  width: 100%;

  & > button {
    padding-left: 0;
  }

  & > div {
    ${({ numberOfLines }) =>
      numberOfLines
        ? `
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: ${numberOfLines};
        line-clamp: ${numberOfLines};
        -webkit-box-orient: vertical;
      `
        : ""}
  }
`;
