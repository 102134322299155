import { WrongParamsError } from "@tour-buddy/core-errors";
import {
  ValidationCheckFunction,
  ValidationSchema,
  ValidationProps,
} from "./types";
import { VALIDATION_FUNCTION_MAP } from "./checkFunctions";

const shouldCheckFunction = (
  validationSchema: ValidationSchema,
  checkKey: string
) => typeof validationSchema[checkKey] !== "undefined";

export const validate = <T extends string>(
  validationSchema: ValidationProps<T>,
  data: Record<T, any>,
  shouldThrowError = true
) => {
  let isError = false;
  let errorMessage: string;
  let errorCode: string;

  try {
    Object.entries<ValidationSchema[]>(validationSchema).forEach(
      ([propertyDataKey, validationSchemaList = []]) => {
        const prop = data[propertyDataKey];

        validationSchemaList.forEach((validationSchemaItem) => {
          const { message, type } = validationSchemaItem;
          const validationFunctionMap = VALIDATION_FUNCTION_MAP[type];

          Object.entries<ValidationCheckFunction<any, any>>(
            validationFunctionMap
          )
            .filter(([validationCheckKey]) =>
              shouldCheckFunction(validationSchemaItem, validationCheckKey)
            )
            .forEach(([validationKey, validationFunction]) => {
              if (!isError)
                if (
                  validationFunction(validationSchemaItem[validationKey], prop)
                ) {
                  isError = true;
                  errorMessage = message;
                  errorCode = validationSchemaItem.errorCode;

                  throw new WrongParamsError({ message, type: errorCode });
                }
            });
        });
      }
    );
  } catch (err) {
    if (shouldThrowError) {
      throw err;
    }
  }

  return {
    isError,
    errorCode,
    errorMessage,
  };
};
