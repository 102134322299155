import { useContext } from "react";
import { TranslationsContext } from "./translationsProvider";
export var replaceTranslationPlaceholders = function (text, translationInputMap) {
    if (translationInputMap === void 0) { translationInputMap = {}; }
    var translationText = text;
    Object.entries(translationInputMap).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        translationText = translationText.replace("{{".concat(key, "}}"), "".concat(value));
    });
    return translationText;
};
export var useTranslation = function (key, translationInputMap) {
    if (translationInputMap === void 0) { translationInputMap = {}; }
    if (!key) {
        return;
    }
    var translations = useContext(TranslationsContext);
    var translationText = translations[key] || "";
    return replaceTranslationPlaceholders(translationText, translationInputMap);
};
